import { useEffect } from 'react';
import WOW from 'wowjs';
import Npp from '../../../assets/js/bootsnav'
import blockbg1 from './../../../assets/img/gallery/blockchain_gray_1.png'
import blockbg3 from './../../../assets/img/blockchain-lead.jpg'
import Footer from './Footer'
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Blockchain() {

  const blockbglead = {
    backgroundImage: `url(${blockbg3})`
  }
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);

  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
     <Seo title={seoData.Blockchain.title} description={seoData.Blockchain.description}/>
      <Npp />
      <div className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="about-items">
            <div className="row align-center1">
              <div className="col-lg-6 col-md-5 wow bounceInLeft">
                <div className="content">
                  {/* <h4>Blockchain</h4> */}
                  <h1 className="mb-2 text-black-40 text-left">
                    Blockchain technology transformative innovation{" "}
                  </h1>
                  <p className="mb-2 text-black-40 text-left">
                    Blockchain technology is a transformative innovation that
                    revolutionizes the way we store, verify, and exchange data and
                    value. At its core, blockchain is a decentralized, immutable, and
                    transparent ledger that enables secure and trustless transactions
                    and interactions.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    With blockchain, data is stored in a distributed network of
                    computers, known as nodes, rather than a central authority. This
                    decentralized architecture eliminates the need for intermediaries,
                    reduces the risk of single points of failure, and enhances the
                    security and integrity of data. The immutability of blockchain
                    ensures that once a transaction is recorded on the ledger, it
                    cannot be altered or tampered with. This feature provides a high
                    level of transparency, trust, and accountability, making
                    blockchain particularly valuable in industries where data
                    integrity is critical, such as finance, supply chain, and
                    healthcare.
                  </p>
      
                </div>
              </div>
              <div className="col-md-6 col-lg-3 offset-lg-2 col-xs-12 wow bounceInRight">
                <img
                  src={blockbg1}
                  alt="Thumb"
                />
              </div>
            </div>
            <div className='row'>
            <div className="col-lg-11 col-md-11 wow bounceInUp">
            <p className="mb-5 text-black-40 text-left">
                    Blockchain also introduces the concept of smart contracts, which
                    are self-executing contracts with predefined rules and conditions.
                    These programmable contracts automatically enforce the terms and
                    conditions of an agreement, eliminating the need for
                    intermediaries and reducing the time and cost associated with
                    traditional contract management. One of the most significant
                    benefits of blockchain is its potential to enable peer-to-peer
                    transactions and empower individuals to have direct control over
                    their digital assets. Blockchain-based cryptocurrencies, such as
                    Bitcoin and Ethereum, enable borderless and frictionless
                    transactions, providing financial inclusion and empowerment to
                    individuals worldwide.
                  </p>

            </div>

            </div>
          </div>
        </div>
      </div>

      <div
        className="why-choose-us-area default-padding-bottom bg-cover text-light"
        style={blockbglead}
      >
        <div className="container">
          <div className="inner-items">
            <div className="row">
              <div className="col-lg-7 offset-lg-5 wow bounceInLeft">
                <div className="info">
                  <p>
                    Beyond finance, blockchain technology has the potential to disrupt
                    various industries, including supply chain, healthcare, voting
                    systems, intellectual property, and more. It enables greater
                    transparency, traceability, and efficiency in processes, enhances
                    data security and privacy, and facilitates new business models and
                    decentralized applications (dApps). However, it's important to
                    acknowledge that blockchain is not a one-size-fits-all solution
                    and may not be suitable for every use case. Factors such as
                    scalability, energy consumption, regulatory frameworks, and
                    adoption challenges need to be considered when implementing
                    blockchain solutions.
                  </p>
                  <p>
                    As blockchain continues to evolve and mature, it has the potential
                    to reshape the digital landscape, foster innovation, and create
                    new opportunities for individuals, businesses, and societies. Its
                    impact reaches far beyond cryptocurrencies, offering a secure and
                    transparent foundation for building trust and enabling the next
                    generation of decentralized applications and systems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-area default-padding1 mt50 bg-gray1 text-darkq">
        <div className="container">
          <div className="testimonial-items text-center1">
            <div className="row">
              <div className="col-lg-9 offset-lg-1 xs-padding-0 wow bounceInRight">
                <div className="heading  mt-5">
                  <p className="mb-2 text-black-40 text-left">
                    Transparent and Immutable Transactions: Blockchain technology
                    eliminates the need for intermediaries by creating a decentralized
                    ledger where transactions are recorded securely and transparently.
                    Every transaction is verified by a network of participants,
                    ensuring its authenticity and immutability. This level of
                    transparency builds trust and enhances the integrity of your
                    business operations.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    Enhanced Security: The blockchain's cryptographic algorithms and
                    distributed architecture provide an unparalleled level of
                    security. Data stored on the blockchain is encrypted, making it
                    highly resistant to unauthorized access, tampering, or fraud. Our
                    blockchain service leverages these robust security measures to
                    safeguard your critical information and assets.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    Smart Contracts for Automation: Smart contracts are self-executing
                    agreements that automatically facilitate, verify, or enforce the
                    performance of contractual obligations. By harnessing the power of
                    smart contracts, our blockchain service enables automation and
                    streamlines complex processes, reducing the risk of human error
                    and ensuring adherence to predefined rules and conditions.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    Supply Chain Management: With the increasing demand for
                    transparency in supply chains, our blockchain service offers a
                    transformative solution. By integrating blockchain technology into
                    your supply chain, you can achieve end-to-end visibility,
                    traceability, and accountability. This enables efficient tracking
                    of products, verification of authenticity, and identification of
                    potential bottlenecks or fraudulent activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Blockchain