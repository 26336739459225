import { useEffect } from 'react'
import Npp from '../../../assets/js/bootsnav'
import Emergtech from './../../../assets/img/gallery/emerging-technology-5.png'
import './emergingtech.css';
import Owlemerging from './Owlemerging';
import Footer from './Footer';
import Respond from './respond';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function EmergingTechnologies() {
  
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>
     <Seo title={seoData.EmergingTechnologies.title} description={seoData.EmergingTechnologies.description}/>
      <Npp />


        <section className="about-area pt176" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-5 align-center wow bounceInLeft">
                <div className="header-text content">
                  {/* <h4>Innovations</h4> */}
                  <h1 className="mb-2 text-black-40 text-left">
                    Emerging technologies{" "}
                  </h1>
                  <p className="mt-2">
                    Emerging technologies are cutting-edge innovations that are poised
                    to transform industries and society. They encompass breakthroughs
                    in areas such as artificial intelligence, quantum computing, and
                    biotechnology, reshaping how we live and work. These dynamic
                    advancements hold the potential to solve complex challenges and
                    drive the future of technological progress.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 offset-lg-2 col-xs-12 wow bounceInRight">
                <div className="right-image">
                  <img
                    src={Emergtech}
                    alt="Thumb"
                  />
                  <img src="assets/img/web3-banner-right-image.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      <div className="case-studies-area default-padding-bottom">
 
        <Owlemerging />
      </div>
      {/* <div className="case-studies-area default-padding-bottom">
 
 <Respond />
</div> */}


      <Footer />
    </>
  )
}

export default EmergingTechnologies