import { useEffect } from 'react';
import uniinfo from './../../../assets/img/uniquness_left1.png';
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';

function Uniqueness() {

  useEffect(() => {
    document.body.classList.remove('dark-layout');
    if (document.body.classList.contains('bg-salmon')) {
      console.log('body tag contains class');
    }
  }, []);

  return (
    <>
      <Npp />
      <div className="about-area services-details-area default-padding pt176">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-5 services-single-content content">
                <h4>Uniqueness</h4>
                <h1 className="mb-2 text-black-40 text-left">Our Uniqueness </h1>
                <p className="mb-2 text-black-40 text-left">
                  What sets our startup company apart is our unparalleled approach to
                  innovation and our distinct value proposition in the market. We are
                  not content with merely following industry norms; instead, we
                  challenge the status quo and push the boundaries of what is
                  possible.
                </p>
                <p className="mb-2 text-black-40 text-left">
                  Our uniqueness stems from our ability to combine cutting-edge
                  technology, creative thinking, and deep customer insights. By
                  blending these elements, we create disruptive solutions that address
                  unmet needs and provide transformative experiences for our
                  customers.
                </p>
                <p className="mb-2 text-black-40 text-left">
                  We pride ourselves on being pioneers, constantly exploring new
                  frontiers and embracing emerging trends. Our commitment to
                  continuous learning and adaptation allows us to stay ahead of the
                  curve and remain at the forefront of industry advancements.
                </p>

              </div>
              <div className="col-md-7 col-lg-5 col-xs-12 marauto pt55">
                <img src={uniinfo} className='mt-5' />
              </div>

              <div className="col-lg-12 col-md-12 col-xs-12">    <div className="features">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="icon">
                      <i className="flaticon-laboratory" />
                    </div>
                    <div className="content">
                      <h4 className="mb-2 text-black-40 text-left">Products </h4>
                      <p className="mb-2 text-black-40 text-left">
                        Moreover, our startup's uniqueness extends beyond our
                        products and services. It permeates our organizational
                        culture, fostering an environment that encourages curiosity,
                        collaboration, and entrepreneurial thinking.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="icon">
                      <i className="flaticon-optimization" />
                    </div>
                    <div className="content">
                      <h4 className="mb-2 text-black-40 text-left">Services</h4>
                      <p className="mb-2 text-black-40 text-left">
                        We empower our employees to bring their diverse perspectives
                        and ideas to the table, fueling a constant flow of fresh
                        insights and approaches.
                      </p>
                    </div>
                  </div>
                </div>
              </div></div>
              <div className="col-lg-12 col-md-12 col-xs-12">
                <p className="mb-2 text-black-40 text-left">
                  Furthermore, our startup is built on the foundation of exceptional
                  quality and attention to detail. We refuse to compromise on the
                  excellence of our offerings, ensuring that every interaction with
                  our brand reflects our commitment to delivering the highest
                  standards of performance and customer satisfaction.
                </p>
                <p className="mb-2 text-black-40 text-left">
                  Lastly, we believe in the power of partnerships and collaborations.
                  By forging strategic alliances with like-minded individuals,
                  organizations, and industry experts, we amplify our uniqueness and
                  multiply our impact. These synergistic relationships enable us to
                  leverage shared strengths, access new markets, and unlock innovative
                  opportunities.
                </p>
                <p className="mb-2 text-black-40 text-left">
                  In summary, our startup company's uniqueness lies in our pioneering
                  spirit, our customer-centric approach, our commitment to excellence,
                  our collaborative culture, and our ability to consistently deliver
                  transformative solutions that differentiate us from the competition.
                  We are proud to stand out as a beacon of innovation and change in
                  our industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );

}

export default Uniqueness;