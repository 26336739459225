import { useEffect } from 'react';
import WOW from 'wowjs';
import Npp from '../../../assets/js/bootsnav'
import blockbg1 from './../../../assets/img/gallery/xrvr_gray_2.png'
import xrvr1 from './../../../assets/img/gallery/xrvr_gray_1.png'
import Footer from './Footer'
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Xrvr() {
  
useEffect(() => {
  const wow = new WOW.WOW({live: false});
  wow.init({
    duration: 1000
  });
  
}, []);


  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>
    <Seo title={seoData.xrvr.title} description={seoData.xrvr.description}/>
      <Npp />
      <div className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-7 col-md-5 wow bounceInLeft">
                <div className="content">
                  {/* <h4>XR</h4> */}
                  <h1 className="mb-2 text-black-40 text-left">
                    XR-VR Environment That Replaces the Real World
                  </h1>
                  <p>
                    XR stands for Extended Reality, which is an umbrella term that
                    encompasses virtual reality (VR), augmented reality (AR), and
                    mixed reality (MR). XR technologies merge the physical and digital
                    worlds, creating immersive and interactive experiences for users.
                  </p>
                  <p>
                    Virtual Reality (VR) refers to a fully immersive,
                    computer-generated environment that replaces the real world. Users
                    wear a VR headset that tracks their head movements and presents a
                    virtual environment, often accompanied by handheld controllers for
                    interaction. VR can transport users to simulated worlds and offer
                    a sense of presence and immersion.
                  </p>
                  <p>
                    Augmented Reality (AR) overlays digital content onto the real
                    world, enhancing the user's perception and interaction with their
                    surroundings. AR is typically experienced through smartphones,
                    tablets, or AR glasses, where virtual elements are seamlessly
                    integrated into the real environment. AR can provide contextual
                    information, interactive elements, and virtual objects in the
                    user's field of view.
                  </p>

                </div>
              </div>
              <div className="col-md-6 col-lg-4 offset-lg-1 col-xs-12 wow bounceInRight">
                <div className="thumb">
                  <img
                    src={xrvr1}
                    alt="Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose-us-area1 default-padding-bottomq bg-cover wow bounceInLeft">
        <section className="section44">
          <div className="col-lg-10">
            <article className="article44">
              <div className="bild" />
              <div className="info44">
                <h1>Mixed Reality</h1>
                <p>
                  Mixed Reality (MR) combines elements of both VR and AR, allowing
                  virtual objects to interact with the real world. MR systems use
                  advanced sensors and cameras to track the user's movements and the
                  surrounding environment, enabling virtual and real-world elements to
                  coexist and interact in real-time. This creates a more dynamic and
                  interactive experience where virtual objects can be manipulated
                  within the real world.
                </p>
              </div>
            </article>
          </div>
        </section>
      </div>
      <div className="testimonials-area default-padding wow bounceInRight">
        <div className="container">
          <div className="testimonial-items text-center1">
            <div className="row">
              <div className="col-lg-3 offset-lg-0">
                <img
                  src={blockbg1}
                  alt="Thumb"
                />
              </div>
              <div className="col-lg-7 offset-lg-1 xs-padding-0">
                <div className="heading">
                  <p>
                    XR technologies have a wide range of applications across various
                    industries. In entertainment, XR can provide immersive gaming
                    experiences, virtual tours of destinations, and interactive
                    storytelling. In education and training, XR can offer realistic
                    simulations, virtual classrooms, and hands-on learning
                    experiences. In healthcare, XR can support medical training,
                    patient rehabilitation, and surgical planning. In architecture and
                    design, XR can facilitate virtual walkthroughs, spatial
                    visualization, and real-time design collaboration. These are just
                    a few examples, and XR continues to evolve, offering new
                    possibilities for communication, entertainment, education, and
                    various other sectors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Xrvr