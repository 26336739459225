import React from 'react';
import { Link } from 'react-router-dom';
import img001 from './../../../assets/img/meet-colate-lines.png';
import release1 from './../../../assets/img/gallery/machine_hand.png';
import release1mob from './../../../assets/img/gallery/machine_hand-mob.png';
import release2 from './../../../assets/img/gallery/banner_slide_1.png';
import release2mob from './../../../assets/img/gallery/topslider-1.jpg';
import release3 from './../../../assets/img/gallery/topslider-2.jpg';
import release3mob from './../../../assets/img/gallery/topslider-3.jpg';
import release4 from './../../../assets/img/digi.jpg';
import release4mob from './../../../assets/img/gallery/ai-slider-3-mob.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './../../../../src/assets/css/topslider.css'
// import required modules
import { Pagination } from 'swiper/modules';
import {  Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
// import required modules
import { Navigation } from 'swiper/modules';
const BannersliderFullwidth = () => {


  return (

    <div className="bg-salmon about-area overflow-hidden pt93">
        <div className="container-fulid">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
         
          <div className='bannerletters'>Navigate Your Next</div>
       
          <img src={release2mob}/>
          </SwiperSlide>
        <SwiperSlide><img src={release3}/>
        <div className='bannerletters'>Fintech</div>
        </SwiperSlide>
        <SwiperSlide><img src={release3mob}/>
        <div className='bannerletters'>Intelligent Automation</div>
        </SwiperSlide>
        <SwiperSlide><img src={release4}/>
        <div className='bannerletters'>Artificial Intelligence</div>
        </SwiperSlide>
        
      </Swiper>
        </div>
      </div>


  )

}
export default BannersliderFullwidth;
