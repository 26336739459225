import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const ClodInfraSlider = () => {
return (
<OwlCarousel
className="owl-theme"

loop
autoplay
responsive={{
0: {
items: 1
},
1000: {
items: 1
},
1200: {
items: 3,

}
}}
>
<div className="item">
<ul className="webapp mt-5">

<li className="card wow bounceInRight">
<div className="title">Scalable Solutions for All Sizes</div>
<div className="content">
<p>Our automation solutions are designed for both small businesses and large enterprises.</p>
<p>Every organization, regardless of its size, deserves efficient and tailored automation tools.</p>
<p>Our solutions are Cost-effective without compromising on quality.</p>
</div>
</li>

</ul>
</div>
<div className="item">
<ul className="webapp mt-5">

     <li className="card webcard wow bounceInRight">
                        <div className="title mb-2 text-black-40 text-left"> Security Fortified, Data Fortresses </div>
                        <div className="content">
                          <p>Say goodbye to security concerns.</p>
                          <p>Our robust, multi-layered security protocols ensure your data remains impenetrable, guaranteeing airtight protection against cyber threats and data breaches.</p>
                        </div>
                      </li>
</ul>
</div>
<div className="item">
<ul className="webapp mt-5">
<li className="card wow bounceInDown">
                        <div className="title">Scalability Beyond Limits</div>
                        <div className="content">
                          <p>Scale your operations effortlessly.</p>
                          <p>Our automated systems are designed to handle your growth, ensuring your infrastructure keeps pace with your expanding business needs, no matter how rapid they may be.</p>
                        </div>
                      </li>
</ul>
</div>
<div className="item">
<ul className="webapp mt-5"> 

<li className="card wow bounceInRight">
                        <div className="title">Experience the Future of Microservices Development</div>
                        <div className="content">
                          <p>Join the growing community of developers and organizations that have accelerated their microservices development with our solutions.</p>
                          <p>Experience the simplicity, scalability, and efficiency of managing container platforms and Kubernates clusters.</p>
                        </div>
                      </li>

</ul>
</div>
<div className="item">
<ul className="webapp mt-5">
<li className="card webcard wow bounceInDown">
                        <div className="title"> Business Logic First, Everything Else Second</div>
                        <div className="content">
                          <p>Your business logic is your unique selling point.</p>
                          <p>With Colate, you can immerse yourself entirely in your core activities while we manage the intricacies of cloud infrastructure.</p>
                          <p>No distractions, just pure focus on what you do best.</p>
                        </div>
                      </li>

</ul>
</div>
<div className="item">
<ul className="webapp mt-5">              <li className="card wow bounceInRight">
                        <div className="title">Automated Monitoring, Zero Downtime</div>
                        <div className="content">
                          <p>Our automated monitoring powered by AI technology ensures seamless operation of your services round the clock.</p>
                          <p>By proactively detecting and resolving issues, we guarantee zero downtime, ensuring uninterrupted access for your customers.</p>
                        </div>
                      </li>

</ul>

</div>
<div className="item">

<ul className="webapp mt-5">             <li className="card wow bounceInRight">
<div className="title">Cost-Effective Excellence</div>
<div className="content">
<p>Budget constraints? Not a problem.</p>
<p>Colate optimizes your cloud spending, ensuring you get the most out of your investment.</p>
<p>Cost-effective solutions without compromising on quality.</p>
</div>
</li></ul>
</div>

<div className="item">
      <ul className="webapp mt-5">
                      <li className="card webcard wow bounceInDown">
                        <div className="title"> Automated Harmony</div>
                        <div className="content">
                          <p>Effortlessly synchronize your desired state stored in Git with the actual system state.</p>
                          <p>Our automation ensures your environments stay perfectly aligned, reducing manual effort and errors.</p>
                        </div>
                      </li></ul>
      </div>


<div className="item">
<ul className="webapp mt-5">  

<li className="card wow bounceInRight">
                            <div className="title">Vendor Agnostic Solutions</div>
                            <div className="content">
                              <p>We support integration with leading cloud providers, such as AWS, Azure, and GCP.</p>
                              <p>Help your team stay secure and compliant every time they make a change to infrastructure.</p>
                            </div>
                      </li>
</ul>

</div>
<div className="item">


</div>
</OwlCarousel>
);
};

export default ClodInfraSlider;