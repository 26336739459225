import { useEffect } from 'react';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import misBgimg from './../../../assets/img/mission-bg-plan.jpeg';
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/stratagy_left.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import img445 from './../../../assets/img/gallery/whitepaper_gray_1_sml.png';
import img450 from './../../../assets/img/gallery/softwaredev-sml.png';
import Newmenubar from './Newmenubar';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Whitepaperlisting() {
const divStyle = {
backgroundImage: `url(${img440})`
}

const misBg = {
backgroundImage: `url(${misBgimg})`
}
useEffect(() => {
document.body.classList.remove('dark-layout');
}, []);

return (
<>
<Seo title={seoData.whitepaperlisting.title} description={seoData.whitepaperlisting.description}/>
<Npp />

<section className="about-area2 section-padding40w pt176 section-bg2 bg-pattern">

<div className="container">
<div className="row align-items-center">
<div className="whitepaper box-whitepaper">
<div className="">
<div className="content-whitepaper">
<div className="relative">
<p className="title-box-yellow minus-top-mob minus-top-lst">
Whitepaper
</p>
<h3>
<Link to="/whitepaper"><a

className="prlst-para"
title="Inspiring Voices – With Pritha Chattopadhyay, Spark NZ"
target="_self"
>
Colate’s role inharnessing AI in CI/CD for Optimized Infrastructure
</a></Link>
</h3>
</div>
<div className="image-box">
<img src={img445} alt="Your Image" />
</div>
</div>
</div>
</div>

<div className="whitepaper box-whitepaper">
<div className="">
<div className="content-whitepaper">
<div className="relative">
<p className="title-box-yellow minus-top-mob minus-top-lst">
Whitepaper
</p>
<h3>
<Link to="/SoftwareDevelopment"><a

className="prlst-para"
title="Inspiring Voices – With Pritha Chattopadhyay, Spark NZ"
target="_self"
>
Integrating AI with CI/CD: A Paradigm Shift in Software Development
</a></Link>
</h3>
</div>
<div className="image-box">
<img src={img450} alt="Your Image" />
</div>
</div>
</div>
</div>





</div>
</div>
</section>


<Footer />
</>
)
}

export default Whitepaperlisting