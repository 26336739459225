import { useEffect } from 'react'
import React, {Component} from 'react';

import Footer from './Footer'
//import TrackVisibility from 'react-on-screen';
//Calling bootstrap
//import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';
import Vision from './Vision';
const ComponentToTrack:any = ({ isVisible }) => {
    const style = {
        background: isVisible ? 'red' : 'blue'
    };
    return <div style={style}>Hello</div>;
}

function SlideWow() {




useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);




 
  
        return(
            <>
     
    
    <div className="container text-center">
    <div className="jumbotron bg-sky">
    <h1>ReactJs test</h1>
    <p>Animmations with WOWjs</p> 
    </div>
    <div className="row">
      <div className="col-sm-4 wow bounceInDown center" data-wow-delay="0.5s">
      <p className="circle-pro bg-yellow">very ES  </p>
      </div>
      <div className="col-sm-4">
        <img className="wow fadeInDown img-thumbnail" data-wow-delay="0.5s" src="https://wowjs.uk/img/wow-logo.jpg" />  
      </div>
      <div className="col-sm-4 wow bounceInDown center" data-wow-delay="0.5s">
      <p className="circle-pro bg-yellow">very ES  </p>
      </div>  
      <div className="col-sm-4 wow bounceInUp center" data-wow-delay="0.5s">
      <p className="circle-pro bg-green">3 KiB only  </p>
      </div>
      <div className="col-sm-4">
        <img className="wow fadeInDown img-thumbnail" data-wow-delay="0.5s" src="https://wowjs.uk/img/wow-logo.jpg" />  
      </div>
      <div className="col-sm-12 wow bounceInDown fadeInUp" data-wow-delay="0.5s">
      <p className="circle-pro bg-green">3 KiB only  </p>
      <p>
                        Beyond finance, blockchain technology has the potential to disrupt
                        various industries, including supply chain, healthcare, voting
                        systems, intellectual property, and more. It enables greater
                        transparency, traceability, and efficiency in processes, enhances
                        data security and privacy, and facilitates new business models and
                        decentralized applications (dApps). However, it's important to
                        acknowledge that blockchain is not a one-size-fits-all solution
                        and may not be suitable for every use case. Factors such as
                        scalability, energy consumption, regulatory frameworks, and
                        adoption challenges need to be considered when implementing
                        blockchain solutions.
                      </p>
                      <p>
                        As blockchain continues to evolve and mature, it has the potential
                        to reshape the digital landscape, foster innovation, and create
                        new opportunities for individuals, businesses, and societies. Its
                        impact reaches far beyond cryptocurrencies, offering a secure and
                        transparent foundation for building trust and enabling the next
                        generation of decentralized applications and systems.
                      </p>    <p>
                        Beyond finance, blockchain technology has the potential to disrupt
                        various industries, including supply chain, healthcare, voting
                        systems, intellectual property, and more. It enables greater
                        transparency, traceability, and efficiency in processes, enhances
                        data security and privacy, and facilitates new business models and
                        decentralized applications (dApps). However, it's important to
                        acknowledge that blockchain is not a one-size-fits-all solution
                        and may not be suitable for every use case. Factors such as
                        scalability, energy consumption, regulatory frameworks, and
                        adoption challenges need to be considered when implementing
                        blockchain solutions.
                      </p>
                      <p>
                        As blockchain continues to evolve and mature, it has the potential
                        to reshape the digital landscape, foster innovation, and create
                        new opportunities for individuals, businesses, and societies. Its
                        impact reaches far beyond cryptocurrencies, offering a secure and
                        transparent foundation for building trust and enabling the next
                        generation of decentralized applications and systems.
                      </p>    <p>
                        Beyond finance, blockchain technology has the potential to disrupt
                        various industries, including supply chain, healthcare, voting
                        systems, intellectual property, and more. It enables greater
                        transparency, traceability, and efficiency in processes, enhances
                        data security and privacy, and facilitates new business models and
                        decentralized applications (dApps). However, it's important to
                        acknowledge that blockchain is not a one-size-fits-all solution
                        and may not be suitable for every use case. Factors such as
                        scalability, energy consumption, regulatory frameworks, and
                        adoption challenges need to be considered when implementing
                        blockchain solutions.
                      </p>
                      <p>
                        As blockchain continues to evolve and mature, it has the potential
                        to reshape the digital landscape, foster innovation, and create
                        new opportunities for individuals, businesses, and societies. Its
                        impact reaches far beyond cryptocurrencies, offering a secure and
                        transparent foundation for building trust and enabling the next
                        generation of decentralized applications and systems.
                      </p>
      </div>  
      <div className="col-sm-4 wow bounceInRight" data-wow-delay="0.5s">
      <p className="circle-pro bg-red">so impress</p>
      </div>
      <div className="col-sm-4">
        <img className="wow fadeInDown img-thumbnail" data-wow-delay="0.5s" src="https://wowjs.uk/img/wow-logo.jpg" />  
      </div>
      <div className="col-sm-4 wow bounceInRight" data-wow-delay="0.5s">
      <p className="circle-pro bg-red">so impress</p>
      </div>  
    </div>
    </div>
    </>
     )
    

}
export default SlideWow