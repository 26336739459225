import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import img240 from './../../../assets/img/gallery/industries-1.jpg';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Industries() {
  return (
    <>
        <Seo title={seoData.Industries.title} description={seoData.Industries.description}/>
      <Npp />
      <div className="about-area overflow-hidden pt176">
        <div className="container">
          <div className="about-items">
            <div className="row align-centerq">
              {/* Thumb */}
              <div className="col-lg-4 col-md-5">
                <div className="thumb">

                  <img src={img240} alt="Thumb" />
                </div>
              </div>
              {/* End Thumb */}
              <div className="col-lg-6 col-md-6 offset-lg-1">
                <div className="content">
                  {/* <h4>Industries</h4> */}
                  <h1 className='mb-2 text-black-40 text-left'>Focus n continuous learning and adaptation</h1>
                  <p className='mb-2 text-black-40 text-left'>
                    Our startup company's strategy is centered around innovation,
                    agility, and market disruption. We will differentite ourselves by
                    continuously challenging the status quo and embracing emerging
                    technologies to deliver unique and compelling products and
                    services. By staying ahead of industry trends and customer needs,
                    we will proactively identify opportunities for growth and
                    expansion.
                  </p>
                  <p className='mb-2 text-black-40 text-left'>
                    We will foster a culture of collaboration, attracting and
                    retaining top talent who share our passion for excellence and
                    entrepreneurial spirit. Through cross-functional teams and a
                    flexible organizational structure, we will promote rapid
                    decision-making and efficient execution. Customer satisfaction
                    will be at the core of our strategy. We will deeply understand our
                    target market, listening to their pain points and constantly
                    seeking feedback. By providing exceptional user experiences,
                    personalized solutions, and exceptional customer service, we will
                    build long-lasting relationships and drive customer loyalty.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt176">
        <div className="row">
          <div className="col-lg-4 col-md-4 mb-4">
            <div className="service">
              <span className="icon icon-energy mb-4 d-block" />
              <h3>
               Fintech
              </h3>
              <p>
                we believe that everyone deserves access to secure, reliable financial
                services that help them achieve their goals and realize their dreams.
                That's why we are committed to developing state-of-the-art products
                and services that empower individuals and organizations to take
                control of their finances and achieve financial freedom.
              </p>
              <a className="btn circle btn-theme border btn-sm" href="fintech.html">
                Rad More
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 mb-4">
            <div className="service">
              <span className="icon icon-magnet mb-4 d-block" />
              <h3>
              TELECOMMUNICATION
              </h3>
              <p>
                {" "}
                At our core, we believe that communication is key to human progress
                and that everyone deserves access to reliable, high-quality
                telecommunications services. That's why we are committed to developing
                cutting-edge products and services that empower individuals and
                organizations to stay connected, no matter where they are in the
                world.
              </p>
              <a
                className="btn circle btn-theme border btn-sm"
                href="telecommunication.html"
              >
                Rad More
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 mb-4">
            <div className="service">
              <span className="icon icon-drawer mb-4 d-block" />
              <h3>
               HEALTHCARE
              </h3>
              <p>
                We believe that healthcare is a fundamental human right, and that
                everyone deserves access to high-quality, affordable healthcare
                services. That's why we are committed to developing cutting-edge
                products and services that empower individuals and organizations to
                achieve their health goals and live their best lives.
              </p>
              <a
                className="btn circle btn-theme border btn-sm"
                href="healthcare.html"
              >
                Rad More
              </a>
            </div>
          </div>
          <div className="row pt176 pb-5">
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-notebook mb-4 d-block" />
                <h3>
                  Hyper-Personalization of Experiences
                </h3>
                <p>
                  {" "}
                  AI will enable hyper-personalization of software experiences by
                  analyzing user behaviors and preferences. This will lead to more
                  tailored software releases and updates, driving user engagement and
                  satisfaction.
                </p>
              </div>
            </div>
            {/* rest list */}
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-bubbles mb-4 d-block" />
                <h3>
                 AI-Powered Autonomous Infrastructure 
                </h3>
                <p>
                  Infrastructure management will become increasingly autonomous, with
                  AI-driven systems handling routine tasks such as scaling, resource
                  provisioning, and troubleshooting. This will free up IT teams to
                  focus on more strategic initiatives.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-trophy mb-4 d-block" />
                <h3>
                  Enhanced Security and Compliance
                </h3>
                <p>
                  AI-powered security solutions will continuously adapt to evolving
                  threats, offering real-time threat detection and rapid response.
                  Compliance monitoring and reporting will also benefit from AI's
                  ability to identify anomalies and patterns.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-organization mb-4 d-block" />
                <h3>
                 AI-Enhanced Code Generation
                </h3>
                <p>
                  {" "}
                  AI-powered code generators and auto-completers will assist
                  developers in writing code more efficiently, reducing coding errors
                  and accelerating development cycles.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-exclamation mb-4 d-block" />
                <h3>
                 Seamless Multi-Cloud Integration
                </h3>
                <p>
                  AI-driven orchestration and resource management will simplify
                  multi-cloud deployments, enabling organizations to seamlessly switch
                  between cloud providers while optimizing costs and performance.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-speedometer mb-4 d-block" />
                <h3>
                 AI-Infused AIOps
                </h3>
                <p>
                  {" "}
                  AI-driven AIOps (Artificial Intelligence for IT Operations)
                  platforms will enhance IT incident management, root cause analysis,
                  and predictive maintenance by identifying patterns and trends in
                  infrastructure performance data.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-graduation mb-4 d-block" />
                <h3>
                  Continuous Learning and Self-Improvement
                </h3>
                <p>
                  AI models integrated into CI/CD pipelines and infrastructure
                  management will continuously learn from data generated during the
                  development process. This self-improvement will lead to refined
                  predictions and optimizations over time.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-4">
              <div className="service">
                <span className="icon icon-fire mb-4 d-block" />
                <h3>
                 Ethics and Explainability
                </h3>
                <p>
                  {" "}
                  As AI's role in development and infrastructure management grows,
                  there will be a greater emphasis on ethical considerations and
                  explainability of AI decisions. Developers and IT professionals will
                  need to ensure AI-driven processes are transparent and fair.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Industries
