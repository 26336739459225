import { faDeleteLeft, faTrash, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'
import AddPage from '../AddPage';
import Author from '../Author';
import Category from '../Category';
import LogoutButton from '../LogoutButton';
import AdminBlogs from '../AdminBlogs';

function Dashboard() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();
    const [pageData, setPageData] = useState<any>([]);
    const [loadPageType, setLoadPageType] = useState('blogs');
    const [showMenu, setShowMenu] = useState(false)

    const handleDelete = (blog: any) => {
        const id = blog._id;
        axios.delete(`https://88y25twng1.execute-api.us-east-1.amazonaws.com/removepage?id=${id}`).then(res => {
            if (res.data) {
                getBlogs();
            }
            // setMessage(res.data.message);
            // setError(false);
        }).catch(e => {
            // setError(true);
        })
    }
    const handlePublish = (blog: any) => {

    }
    const getBlogs = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allPage`).then((res) => {
            if (res?.status === 200) {
                setPageData(res.data);
            }
        })
    }
    const handleMenuClick = (type: string) => {
        setLoadPageType(type);
    }
    const renderPage = () => {
        switch (loadPageType) {
            case 'blogs':
                return (
                    <AdminBlogs />
                );
                break;
            case 'blog':
                return (
                    <AddPage />
                );
            case 'author':
                return (
                    <Author />
                );
            case 'category':
                return (
                    <Category />
                );
            default:
                break;
        }
    }
    const redirectToLogin = () => {
        navigate('/login');
    }

    useEffect(() => {
        getBlogs();
    }, [])

    if (isLoading) {
        return <div>Loading ...</div>;
    }
    return (
        <>
            {isAuthenticated ? (
                <div className='container-fluid p-0'>
                    <div className="bg-light pt-2 ps-5 topLogobar">
                        <a href="/"><h1 className="colate-logo ">colate</h1></a>
                        <div className='logOutBtn pt-3'>
                            <div className="dropdown">
                                <FontAwesomeIcon className="actionIcon" onClick={() => setShowMenu(!showMenu)} icon={faUser} />
                                <ul className="dropdown-menu profileList" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <li><a className="dropdown-item" href="#">{user?.name}</a></li>
                                    <li><a className="dropdown-item" href="#">{user?.email}</a></li>
                                    <li><LogoutButton /> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 ps-5 pe-5">
                        <div className="col">
                            <ul className="nav nav-pills">
                                <li className="nav-item" onClick={() => handleMenuClick('blogs')}>
                                    <a className={loadPageType === 'blogs' ? 'nav-link fw-bold active' : 'nav-link fw-bold'} >Blogs</a>
                                </li>
                                <li className="nav-item" onClick={() => handleMenuClick('author')}>
                                    <a className={loadPageType === 'author' ? 'nav-link fw-bold active' : 'nav-link fw-bold'}>Author</a>
                                </li>
                                <li className="nav-item" onClick={() => handleMenuClick('category')}>
                                    <a className={loadPageType === 'category' ? 'nav-link fw-bold active' : 'nav-link fw-bold'}>Category</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-9 shadow-sm p-3">
                            {renderPage()}
                        </div>
                    </div>

                </div>
            ) : redirectToLogin()}
        </>

    )
}

export default Dashboard