import { useEffect } from 'react';
import WOW from 'wowjs';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import misBgimg from './../../../assets/img/mission-bg-plan.jpeg';
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/gallery/app_devlopment_500-700.png';
import missvg from './../../../assets/img/mission_content_1png.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';

function Vision() {
  const divStyle = {
        backgroundImage: `url(${img440})`
  }

  const misBg = {
    backgroundImage: `url(${misBgimg})`
  }

  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>
    <Seo title={seoData.mission.title} description={seoData.mission.description}/>
      <Npp />
      <section className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="row align-items-center1 wow bounceInUp">
            <div className="col-lg-6 col-md-5">
              <div className="about-caption mb-50">
                <div className="section-tittle mb-25">
                
                  <h1 className="mb-2 text-black-40 text-left">Our Mission</h1>
                </div>
                <p className="mb-20">
                  At Colate, our mission is to empower businesses through cutting-edge technology solutions.
                </p>
                <p>
                We are committed to delivering exceptional web and mobile development services, seamless cloud infrastructure automation, and efficient DevOps solutions.
                </p>
                <p>
                With a focus on innovation and expertise, we enable our clients in finance, telecom, hardware engineering, healthcare, and various other industries to thrive in the digital landscape.
                </p>
                <p>
                We strive to be the trusted partner that transforms ideas into scalable and sustainable realities, driving the growth and success of businesses worldwide.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 offset-lg-1 col-xs-12">
              <div className="about-img">
                <img
                  src={uniinfo}
                  alt="Thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="about-area overflow-hidden default-padding1 pt176">
              <section className="c-section">
                <ul className="c-services">
                  <li className="c-services__item wow bounceInRight">
                    <h3>Innovative Sustainability</h3>
                    <p>
                      We innovate with a focus on environmental responsibility,
                      ensuring our solutions are eco-friendly, secure, and tailored to meet
                      the unique needs of our clients.
                    </p>
                  </li>
                  <li className="c-services__item wow bounceInLeft">
                    <h3>Collaborative Solutions</h3>
                    <p>
                      We collaborate closely with our clients, understanding their challenges and objectives,
                      to co-create innovative and customized technology solutions.
                    </p>
                  </li>
                  <li className="c-services__item wow bounceInRight">
                    <h3>Affordable Excellence</h3>
                    <p>
                      We deliver top-notch services at accessible prices, empowering businesses
                       of all sizes with tailored, innovative, and secure technology solutions.
                    </p>
                  </li>
                  <li className="c-services__item wow bounceInLeft">
                    <h3>Empowering Small Businesses</h3>
                    <p>
                      We empower startups and small enterprises by providing affordable tech solutions that fuel
                      growth and innovation, safeguarding their digital assets with cutting-edge security measures.
                    </p>
                  </li>
                  <li className="c-services__item wow bounceInRight">
                    <h3>Resource Efficiency</h3>
                    <p>
                      We optimize resources, minimizing waste and energy consumption in our operations and solutions,
                      parallel with stringent data security practices to protect sensitive information.
                    </p>
                  </li>
                  <li className="c-services__item wow bounceInLeft">
                    <h3>Customer-Centric Accessibility</h3>
                    <p>
                      We prioritize user-friendly designs, ensuring our technology is accessible to all users,
                      including those with disabilities, without compromising data integrity and security.
                    </p>
                  </li>
                </ul>
              </section>
              <section className="wantToWork-area default-padding ">
                  <div className="container">
                    <div
                      className="wants-wrapper w-padding2 section-bg2"
                      style={divStyle}
                    >
                      <div className="row align-items-center justify-content-center wow bounceInDown">
                        <div className="col-xl-7 col-lg-9 col-md-8">
                          <div className="wantToWork-caption text-center">
                            <h2>Discover Our Diverse Range of Solutions</h2>
                            <p>
                              At Colate, we empower businesses with Cutting-edge Technology, DevOps Excellence, and Innovative Solutions
                              Tailored to Your Unique Requirements. Explore the Future of Digital Transformation!
                            </p>
                            <a href="/Services" className="btn3 wantToWork-btn">
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
              </section>
            </div>
      <Footer />
    </>
  )
}

export default Vision