import { useEffect } from 'react';
import WOW from 'wowjs';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import misBgimg from './../../../assets/img/mission-bg-plan.jpeg';
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/stratagy_left.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import Newmenubar from './Newmenubar';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';

function Vision() {
  const divStyle = {
      backgroundImage: `url(${img440})`
  }

  const misBg = {
    backgroundImage: `url(${misBgimg})`
  }
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);

  return (
    <>
    <Seo title={seoData.vision.title} description={seoData.vision.description}/>
      <Npp />
    
      <section className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="row align-items-center wow bounceInDown">
            <div className="col-lg-5 col-md-6">
              <div className="about-caption mb-50">
                <div className="section-tittle mb-25">
                <h1 className="mb-2 text-black-40 text-left">Our Vision</h1>
             
                </div>
                <p className="mb-20">
                  At Colate, we aspire to create a sustainable and inclusive digital future.
                </p>
                <p>
                Our goal is to pioneer sustainable technology solutions, removing accessibility barriers and enabling businesses of all sizes to prosper.
                </p>
                <p>
                We envision a world where innovation, responsibility, and affordability drive businesses, positively impacting the global economy.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 offset-lg-2 col-xs-12">
        
              <div className="about-img">
                <img
                  src={uniinfo}
                  alt="Thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="about-area overflow-hidden default-padding1 pt176">
          <section className="c-section">
            <ul className="c-services">
              <li className="c-services__item  wow bounceInRight">
                <h3>Global Impact</h3>
                <p>
                  We aspire to make a global impact by promoting sustainable practices and
                  accessible technology solutions worldwide, integrating robust security
                  protocols to ensure data privacy and online safety on a global scale.
                </p>
              </li>
              <li className="c-services__item  wow bounceInLeft">
                <h3>Tech for All</h3>
                <p>
                  We envision a future where every business, regardless of scale or budget,
                  can leverage technology for success, fortified by uncompromising security
                  measures to build trust and confidence in the digital landscape.
                  Our tailored solutions cater to diverse business needs.
                </p>
              </li>
              <li className="c-services__item wow bounceInRight">
                <h3>Inclusivity Drive</h3>
                <p>
                  We are driven to bridge the digital divide, making sure no business is left behind in the digital era,
                  ensuring that cybersecurity is an integral part of inclusivity,
                  protecting businesses of all sizes with customized, innovative, and secure solutions.
                </p>
              </li>
              <li className="c-services__item wow bounceInLeft">
                <h3>Environmental Stewardship</h3>
                <p>
                  We are committed to being stewards of the environment, integrating green practices
                  into every aspect of our work, while ensuring that our cyber security measures
                  safeguard both digital and natural ecosystems.
                  Our tailored solutions adapt to evolving environmental challenges.
                </p>
              </li>
              <li className="c-services__item wow bounceInRight">
                <h3>Setting New Industry Standards</h3>
                <p>
                  At Colate, we don't just keep up with industry trends; we set new standards.
                  We strive for continuous innovation in sustainability, accessibility, security,
                  collaboration, and tailored solutions, setting new industry standards and inspiring
                  positive change. Our commitment to innovation ensures our clients receive cutting-edge
                  and customized technology solutions that evolve with their needs.
                </p>
              </li>
              <li className="c-services__item wow bounceInLeft">
                <h3>Continuous Innovation</h3>
                <p>
                  At Colate, we envision a future where software innovations transcend boundaries, empowering businesses,
                  and individuals to achieve their full potential.
                  Our vision is to be the pioneers of transformative software solutions,
                  catalysing digital evolution and enhancing lives across the globe.
                </p>
              </li>
            </ul>
          </section>
          <section className="wantToWork-area default-padding ">
            <div className="container">
              <div
                className="wants-wrapper w-padding2 section-bg2"
                style={divStyle}
              >
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-7 col-lg-9 col-md-8">
                    <div className="wantToWork-caption text-center">
                      <h2>Discover Our Diverse Range of Solutions</h2>
                      <p>
                        At Colate, we empower businesses with Cutting-edge Technology, DevOps Excellence, and Innovative Solutions
                        Tailored to Your Unique Requirements. Explore the Future of Digital Transformation!
                      </p>
                      <a href="/Services" className="btn3 wantToWork-btn">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
          </section>
      </div>
      <Footer />
    </>
  )
}

export default Vision