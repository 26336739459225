import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Npp from '../../assets/js/bootsnav';

function Blogs() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [pageData, setPageData] = useState<any>([]);
    useEffect(() => {
        if(state) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/blog`, { id:state }).then((res) => {
                if (res?.status === 200) {
                    setPageData(res.data);
                }
            })
        }
        
    }, [state])

   

  return (
    <>
    <Npp />
    <div className="blog-area full-blog default-padding">
        <div onClick={() => navigate('/blogs')} style={{cursor:'pointer', width: '60px', height: '60px', position: 'fixed', right: '7rem'}}><img src={require('../../assets/img/backbtn.jpg')} /> </div>
        <div className="container">
            <div className="row">
                <div className="blog-items">
                    <div className="blog-content col-lg-10 offset-lg-1">
                        <div className="blog-item-box">
                            {pageData.map((item:any) => (
                                <div className="single-item">
                                <div className="item">
                                    <div className="thumb">
                                        <img src={item.img} alt="Thumb" />
                                        <div className="date">15 Jul, 2021</div>
                                    </div>
                                    <div className="info">
                                        <h3>
                                            <a href="#">{item.title}</a>
                                        </h3>
                                        <div className="footer-meta">
                                           <ul>
                                               <li>
                                               <img src={item?.author[0].profilePicture} alt="Author" />
                                                   <span>By </span>
                                                   <a href="#">{item?.author[0].name}</a>
                                               </li>
                                               <li>
                                                   <span>In </span>
                                                   <a href="#">{item?.category[0].name}</a>
                                               </li>
                                           </ul>
                                        </div>
                                        <p dangerouslySetInnerHTML = {{__html: item.description}}></p>
                                        {/* <a className="btn circle btn-theme border btn-sm" onClick={() => handleReadMore(item._id)}>Read More</a> */}
                                    </div>
                                </div>
                            </div>
                            ))}
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Blogs;