import axios from 'axios'
import React, { ChangeEvent, useEffect, useState } from 'react'
import './addpage.css'
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { authorType } from './Author';
import { categoryType } from './Category';
import Editor from './Editor';

function AddPage({selectTab}:any) {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState<any>();
    const [author, setAuthor] = useState<authorType[]>([]);
    const [category, setCategory] = useState<categoryType[]>([]);


    const createPagePayload = {
        title: '',
        imgName: '',
        img: '' as any,
        description: '',
        categoryId: '',
        authorId: '',
        overView: ''
    }
    const handleCreatePage = () => {
        if (createPagePayload.title.trim() == '') {
            alert("plase enter title");
        } else if (createPagePayload.imgName.trim() == '') {
            alert("plase enter imgname");
        } else if (createPagePayload.description.trim() == '') {
            alert("plase enter description");
        } else if (createPagePayload.overView.trim() == '') {
            alert("plase enter overView");
        } else {
            axios.post(`${process.env.REACT_APP_BASE_URL}/addPage`, createPagePayload).then(res => {
                setMessage(res.data.message);
                setError(false);
                selectTab(1);
            }).catch(e => {
                setError(true);
            })
        }
    }
    const fileToDataUri = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event?.target?.result)
        };
        reader.readAsDataURL(file);
    })

    const redirecToHomePage = () => {
        navigate('/');
    }

    const handleChange = (event: any) => {
        const name = event.target.name;
        // loginPayload[name] = event.target.value;
        if (name === 'title') {
            createPagePayload.title = event.target.value;
        } /* else if (name === 'description') {
            createPagePayload.description = event.target.value;
        } */ else if (name === 'bannerImg') {
            fileToDataUri(event.target.files[0]).then(dataUri => {
                createPagePayload.img = dataUri;
            })
            // let reader = new FileReader();
            // reader.readAsDataURL(event.target.files[0]);
            createPagePayload.imgName = event.target.files[0].name;

        } else if (name === 'author') {
            createPagePayload.authorId = event.target.value;
        } else if (name === 'category') {
            createPagePayload.categoryId = event.target.value;
        } else if (name === 'overView') {
            createPagePayload.overView = event.target.value;
        }
    }

    const getAuthors = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allauthor`).then(res => {
            setAuthor([...res.data]);
        })
    }
    const getCategories = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allCategory`).then(res => {
            setCategory([...res.data]);
        })
    }
    useEffect(() => {
        getAuthors();
        getCategories();
    }, [])
    const [editorLoaded, setEditorLoaded] = useState(false);
    // const [data, setData] = useState("");

    useEffect(() => {
        setEditorLoaded(true);
    }, []);


    return (
        <>
            {isAuthenticated ? (
                <>
                    <div className="container-fluid p-0">
                        <div className="row ">
                            <div className="col-10 shadow-sm leftSideSpace">
                                <div className="row">
                                    <div className="col-md-12">
                                        {message && error && (<div className='alert alert-danger' role="alert">{message}</div>)}
                                        {message && !error && (<div className='alert alert-success' role="alert">{message}</div>)}
                                        <h5 className="card-title fw-bold pb-3">Add Blog</h5>
                                    </div>
                                    <div className="col-md-12">
                                        <form >
                                            <div>
                                                <label htmlFor="exampleInputEmail1" className="form-label">Title</label>
                                                <input onChange={handleChange} type="text" className="form-control" name="title" />
                                            </div>
                                            <div>
                                                <label htmlFor="exampleInputEmail1" className="form-label">Overview</label>
                                                <input onChange={handleChange} type="text" className="form-control" name="overView" />
                                            </div>
                                            <div>
                                                <label htmlFor="exampleInputEmail1" className="form-label">Category</label>
                                                <select onChange={handleChange} name='category' className="form-select form-control categorySelect" aria-label="Default select example">
                                                    <option selected>Select</option>
                                                    {category.map(cat => (
                                                        <option key={cat._id} value={cat._id}>{cat.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="exampleInputEmail1" className="form-label">Author</label>
                                                <select onChange={handleChange} name='author' className="form-select form-control categorySelect" aria-label="Default select example">
                                                    <option selected>Select</option>
                                                    {author.map(au => (
                                                        <option key={au._id} value={au._id}>{au.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label> Banner Image </label>
                                                <div className="input-group">
                                                    <input onChange={handleChange} type="file" name="bannerImg" multiple />
                                                    {/*  <span className="input-group-btn">
                                                        <span className="btn btn-file " id='custom-button'>
                                                            Browse <input onChange={handleChange} type="file" name="bannerImg" multiple />
                                                        </span>
                                                    </span> */}
                                                    <input type="text" className="form-control" readOnly />
                                                </div>
                                            </div>

                                            <div className='blogArea'>
                                                <Editor
                                                    name="description"
                                                    onChange={(data: any) => {
                                                        // setData(data);
                                                        createPagePayload.description = data;
                                                    }}
                                                    editorLoaded={editorLoaded}
                                                />
                                                {/* <textarea onChange={handleChange} rows={9} cols={6} id="code_preview0  full-featured-non-premium" className="form-control bcontent" name="description" aria-multiline="true"></textarea> */}

                                            </div>
                                            <div>
                                                <button type="button" onClick={handleCreatePage} className="btn btn-primary col-2">Submit</button>
                                                {/* <div className="button">
                                                    <input onClick={handleCreatePage} type="button" name="Submit" value="Publish" className="btn login-button form-control" />

                                                </div> */}
                                            </div>
                                            <div></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>
                    </div>
                </>
            ) : redirecToHomePage()}
        </>
    )
}

export default AddPage