import React,{Component} from 'react';  

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

export class Owlemerging extends Component {  


        render()  
        {  
          return (  
            <div>  
  <div className="container-fill mt-5">

  <div className="case-carousel">
      <OwlCarousel
        className="owl-theme"
        loop
        center
        margin={30}
        nav
        navText={[
          "<i class='arrow_left'></i>",
          "<i class='arrow_right'></i>"
        ]}
        dots={false}
        autoplay
        responsive={{
          0: {
            items: 1
          },
          1000: {
            items: 2
          },
          1200: {
            items: 3,
            stagePadding: 100
          }
        }}
      >
        {/* Your carousel items go here */}
        <div className="item">
        <div className="thumb">
          <img src={ require('./../../../assets/img/cyber.jpg')} alt="Thumb" />
          
        </div>
        <div className="info">
          <h4>
            <i><a href="/CloudInfrastructure">Cloud Infrastructure Automation</a></i>
          </h4>
          <div className="tags">
            <p>Streamlining businesses through seamless and scalable cloud infrastructure automation solutions.</p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="thumb">
          <img src={ require('./../../../assets/img/it-consultancy.jpg')} alt="Thumb" />
         
        </div>
        <div className="info">
          <h4>
            <i><a href="/DevopsAutomation">DevOps Automation</a></i>
          </h4>
          <div className="tags">
            <p>
              Accelerating software development lifecycles through efficient DevOps and CI/CD automation.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="thumb">
          <img src={ require('./../../../assets/img/analysis.jpg')} alt="Thumb" />
          
        </div>
        <div className="info">
          <h4>
            <i><a href="/DevopsRelease">DevOps Release Management</a></i>
          </h4>
          <div className="tags">
            <p>
              Efficiently orchestrating DevOps release management for smooth, reliable, and agile software deployments.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="thumb">
          <img src={ require('./../../../assets/img/social.jpg')} alt="Thumb" />
          
        </div>
        <div className="info">
          <h4>
            <i><a href="/ApplicationDevlopment">Application Development</a></i>
          </h4>
          <div className="tags">
            <p>
              Crafting innovative and responsive digital experiences, from dynamic web applications to mobile solutions, with a focus on reactivity and user engagement.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="thumb">
          <img  src={ require('./../../../assets/img/web3.jpg')}  alt="Thumb" />
       
        </div>
        <div className="info">
          <h4>
            <i><a href="/Web3">Web 3.0</a></i>
          </h4>
          <div className="tags">
            <p>
              Leading the evolution of the internet with Web 3.0 technologies, enabling decentralized,
              secure, and immersive digital experiences.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="thumb">
          <img src={ require('./../../../assets/img/blockchain.jpg')} alt="Thumb" />
          
        </div>
        <div className="info">
          <h4>
            <i><a href="/Blockchain">Blockchain</a></i>
          </h4>
          <div className="tags">
            <p>
              Empowering businesses with transformative blockchain solutions, ensuring secure,
              transparent, and efficient transactions in the digital era.
            </p>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="thumb">
          <img src={ require('./../../../assets/img/xr.jpg')}  alt="Thumb" />
        </div>
        <div className="info">
          <h4>
            <i><a href="/Xrvr">XR (AR/VR)</a></i>
          </h4>
          <div className="tags">
            <p>
              Transforming reality through immersive XR experiences,
              seamlessly blending the physical and digital worlds with cutting-edge Augmented and Virtual Reality solutions.
            </p>
          </div>
        </div>
      </div>
        {/* Add more items as needed */}
      </OwlCarousel>
    </div>
  </div>
      </div> 

          )  
        }  
      }  

export default Owlemerging  