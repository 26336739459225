import { useEffect } from 'react';
import WOW from 'wowjs';
import misBgimg from './../../../assets/img/mission-bg-plan.jpeg';
import img440 from './../../../assets/img/new-secion-vr.png';
import './../../../pages/UI/common/Fin.css';
import bannervdo from './../../../assets/img/colate-banner-vdo.mp4';

import release3mob from './../../../assets/img/gallery/brain_ai_mob.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import './../../../../src/assets/css/topslider.css'
import { Navigation, Autoplay } from 'swiper/modules';


import 'swiper/css';
import 'swiper/css/navigation';
function VideoBanner() {
    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init({
            duration: 5500
        });

    }, []);
    const divStyle = {
        backgroundImage: `url(${img440})`
    }

    const misBg = {
        backgroundImage: `url(${misBgimg})`
    }
    useEffect(() => {
        document.body.classList.remove('dark-layout');
    }, []);

    return (
        <>

            <section className="about-area2 section-padding40w section-bg2">
                <div className="container-fluid centertext">

                    <div className="video-banner">
                        <video className="home-banner-video" autoPlay loop muted>
                            <source src={bannervdo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="video-banner__overlay">
                            <div className="container">
                                <div className="swiper swiper-initialized swiper-horizontal homeslidertop marStyle swiper-backface-hidden ">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide swiper-slide-active">
                                            <div className="flex-item-slider vh100w offset-lg-0 col-lg-8 col-sm-8 col-xs-12">
                                                <div className="1wrapvh"><h1 className="slidertxt"> Collaborate Innovate Solve</h1><p data-animation="animated slideInLeft mobtop50">Together, we pioneer solutions for a brighter tomorrow, crafting exceptional software innovations that transform ideas into reality and empower businesses to thrive in the digital age</p></div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide swiper-slide-next">
                                            <div className="flex-item-slider vh100w offset-lg-0 col-lg-8 col-sm-8 col-xs-12">
                                                <div className="1wrapvh"><h1 className="slidertxt ">Artificial Intelligence</h1><p data-animation="animated slideInLeft marginbottom-xs">Artificial Intelligence, the digital sentinel, transforms data into insights, navigating the realms of possibility with computational prowess.</p></div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="flex-item-slider vh100w offset-lg-0 col-lg-8 col-sm-8 col-xs-12">
                                                <div className="11wrapvh"><h1 className="slidertxt ">Fintech</h1><p data-animation="animated slideInLeft marginbottom-xs">the financial revolution's heartbeat, melds tech prowess with monetary streets. Innovating transactions, it unlocks a future where finance and technology meet.</p></div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide"><div className="flex-item-slider vh100w offset-lg-0 col-lg-8 col-sm-8 col-xs-12">
                                            <div className="1wrapvh"><h1 className="slidertxt ">Intelligent Automation</h1><p data-animation="animated slideInLeft mobtop50"> Intelligent automation, where algorithms reign, orchestrating tasks with precision, a digital domain. Efficiency's maestro, innovation's crown, it's the future's melody, where human and tech renown.</p></div>
                                            <img src="/static/media/brain_ai_mob.d037f16ae1cfb378e220.png" alt="Thumb" className="img-fluid no-shrink mobshow" /></div></div></div><div className="swiper-button-prev swiper-button-disabled">
                                    </div>
                                    {/* <div className="swiper-button-next"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default VideoBanner