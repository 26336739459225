import { useEffect, useState } from 'react'
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import WOW from 'wowjs';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
import Trustedpartner from '../common/Trustedpartner'
import './../common/contactus.css'
import vdo from './../../../assets/img/london-vdo.mp4'
import contactform from './../../../assets/img/contact-form.png'
import insta  from './../../../assets/img/insta.png'
import face  from './../../../assets/img/faceB.png'
import utube  from './../../../assets/img/utube.png'
import xt  from './../../../assets/img/xt.png'
import bengaluru  from './../../../assets/img/banglore-icon.png'
import united from './../../../assets/img/london-icon.png'
import newyork from './../../../assets/img/newyork-icon.png'

function Contact() {

  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  const [errorMessage, setErrorMessage] = useState('')
  const contactInfo: any = {
    name: '',
    email: '',
    city: '',
    country: '',
    comments: ''
  }
  const handelContactInfo = (event: any) => {
    const fieldName = event.target.name;
    contactInfo[fieldName] = event.target.value;
  }
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const validateForm = () => {
    if (contactInfo.name.trim() === '') {
      setErrorMessage('Name is mandatory.')
      return false;
    }
    if (contactInfo.email.trim() === '') {
      setErrorMessage('Email is mandatory.')
      return false;
    }
    if (!isValidEmail(contactInfo.email.trim())) {
      setErrorMessage('Please provide a valid email address.')
      return false;
    }
    if (contactInfo.city.trim() === '') {
      setErrorMessage('city is mandatory.')
      return false;
    }

    if (contactInfo.country.trim() === '') {
      setErrorMessage('country is mandatory.')
      return false;
    }
    if (contactInfo.comments.trim() === '') {
      setErrorMessage('Please fill in the comments section.')
      return false;
    }
    return true;
  }
  const submitForm = () => {
    if (validateForm()) {
      fetch('https://kpw3kn2cmg.execute-api.us-east-1.amazonaws.com/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(contactInfo)
      })
        .then(response => {
          if (response.ok) {
            setErrorMessage('Your query has been submitted! We will contact you shortly.')
          } else {
            setErrorMessage('Something went wrong!')
          }
        })
        .catch(error => {
          setErrorMessage('Something went wrong!')
        });
    }
  }
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
    <Seo title={seoData.Contact.title} description={seoData.Contact.description}/>
      <Npp />
      

      {/* Headquarter section */}
      <section className="about-area2 section-padding40w 2pt100 section-bg2 1contact-head">
        <div className="container-fluid centertext">
          {/* <div className="row align-items-center">
     <div className='col-lg-4 col-sm-6 col-md-4 col-xs-12 offset-lg-1 vdiv-bg'>
          <div className='realestateblock2'>
            <h1 className='whitecolor wow bounceInUp mt35'>Headquarter<span className='block'></span>
              London</h1>

<p className='mt-5'>22 Baker Street, London, United Kingdom, W1U 3BW</p>
          </div>
          </div>
          </div> */}
   <div className="video-banner">
      <video className="video_banner__video" autoPlay loop muted>
        <source src={vdo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video_banner__overlay">
      <h1>Contact Info</h1>
      <p>Headquarters</p>
        <h2>22 Baker Street, London</h2>
        <h2>United Kingdom, W1U 3BW</h2>
        <h2>Phone:
+44-77-7424-9876</h2> 
<h2>Email:
admin@colate.io</h2> 
      </div>
    </div>
        </div>
      </section>
<div className='clearfix'></div>
       <section className='realative'>
        <article>
          <div className='container-fluid '>
            <div className='row'>
              <div className='col-lg-12 col-12'>

              <div className="center-hor-ver">
  
              <div id="subscription_area">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <div className="subscribe_now text-center">
          <h4>subscribe</h4>
          <p>Get connected to our insights</p>
          <div className="subscribe-container">
        <input type="email" className="subscribe-input" placeholder="Enter your email" />
        <button className="subscribe-button">Subscribe</button>
    </div>
        </div>
      </div>
    </div>
  </div>
</div>
  
</div>
              </div>
            </div>
          </div>
        </article>
      </section> 


      {/* Headquarter section end */}
      <section className="contact_us pt176 wow bounceInUp">
        <div className="container">
          <div className="row">
            <div className="col-md-11 offset-md-0">
              <div className="contact_inner">
                <div className="row">
                  <div className="col-md-7">
                    <div className="contact_form_inner">
                      <div className="contact_field">
                        <h4><strong>Send us your query, and our team will respond shortly!</strong></h4>
                        <div className='col-lg-8 col-sm-8 col-xs-12'>
                          <input
                            type="text"
                            className="form-control form-group"
                            placeholder="Name"
                            onChange={handelContactInfo}
                            name="name"

                          />
                        </div>
                        <div className='col-lg-8 col-sm-8 col-xs-12'>
                          <input
                            type="text"
                            className="form-control form-group"
                            placeholder="Email"
                            onChange={handelContactInfo}
                            name="email"
                          />
                        </div>
                        <div className='col-lg-8 col-sm-8 col-xs-12'>
                          <input
                            type="text"
                            className="form-control form-group"
                            placeholder="City"
                            onChange={handelContactInfo}
                            name="city"
                          />
                        </div>

                        <div className='col-lg-8 col-sm-8 col-xs-12'>
                          <input
                            type="text"
                            className="form-control form-group"
                            placeholder="Country"
                            onChange={handelContactInfo}
                            name="country"
                          />
                        </div>
                        <div className='col-lg-8 col-sm-8 col-xs-12'>
                          <textarea
                            className="form-control form-group"
                            placeholder="Comments"
                            onChange={handelContactInfo}
                            name="comments"
                          />
                        </div>


                        <div className='col-lg-4 col-sm- col-xs-12'>
                          {/* <a className='btn-contact btn-color btn-l-r'>Load Left to Right</a> */}
                          <button className="contact_form_submit" onClick={submitForm}>Send</button>


                        </div>
                        <div className="col-lg-12 alert-notification">
                          <div id="message" className="alert-msg">{errorMessage}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="right_conatct_social_icon d-flex bg-dark2">
                    <div className="contact_info_sec">
                  <div className="d-flex info_single align-items-center">
           <img src={contactform} className='img-fluid'/>

                  </div>

    
                
                </div>
                    </div>
                  </div>
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </section>


{/* sticky icons */}

<div className="sticky-icon">
  <a href="https://www.instagram.com/?hl=en" className="Instagram"><img src={insta}/> Instagram</a>
   <a href="https://www.facebook.com/" className="Facebook"><img src={face}/> Facebook</a>
  
   <a href="https://www.youtube.com/" className="Youtube"><img src={utube}/>  You Tube</a>
   <a href="https://twitter.com/login" className="Twitter"><img src={xt}/> Twitter</a>   
</div>
{/* sticky icons end */}

      <section className="contact_us_location pt176 wow bounceInUp">
        <div className="container">
          <div className="row">
          <div className="flex-container">
            <div><h2>Location</h2></div>
          </div>

            <div className="flex-container mt-5">
   <div className="flex-items-location"> <b>United Kingdom</b><br/>London,<br/>	49 Featherstone Street
   <div><img src={newyork}/></div>
   </div>

   <div className="flex-items-location">  <b>New York</b><br/> 47 W 13th St, New York, NY 10011
   <div><img src={united}/></div>
   </div>
   <div className="flex-items-location"> <b>India</b><br/>100 Feet Road
Road in Bengaluru, Karnataka
<div><img src={bengaluru} /></div>
</div>

</div>
            </div>
            </div>
            </section>

      <section className="map_sec clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="map_inner">
                <h4>Find Us on Google Map</h4>
                <div className="map_bind">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15560.156292061363!2d77.64788928506016!3d12.840751670616909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6c84c653f867%3A0xa2b7177c72352af9!2sElectronics%20City%20Phase%201%2C%20Electronic%20City%2C%20Bengaluru%2C%20Karnataka%20560100!5e0!3m2!1sen!2sin!4v1695470923630!5m2!1sen!2sin"
                    width="100%"
                    height={450}
                    style={{ border: 0 }}

                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Contact