import Npp from '../../../assets/js/bootsnav';
import Footer from './Footer';
import img400 from './../../../assets/img/iPhone-15.png';
import abt from './../../../assets//img/about.svg';
import img420 from './../../../assets/img/whitepaer_thumb1.png';
import img430 from './../../../assets/img/v-realty-1.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import img450 from './../../../assets/img/benifit_vr.png';
import img460 from './../../../assets/img/illustration/blockchain-thumb.svg';

function VisionReality() {
  const divStyle = {
    backgroundImage: `url(${img440})`
  }
  const img4601 = {
    backgroundImage: `url(${img460})`
  }
  return (
    <>
      <Npp />
      <div className="main-banner hero-image2 ">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-5">
                <div className="row">
                  <div className="col-lg-6 align-self-start">
                    <h1 className="mb-2 text-black-40 text-left">
                      Let's Transform Your Vision into Reality!
                    </h1>
                    <p>
                      Ready to take the next step? Contact us today to discuss your
                      project, receive a free quote, and embark on a journey to bring
                      your application to life!
                    </p>
                  </div>
                  <div className="col-lg-6 align-self-center mt-5">
                    <div className="about-img robokid">
                      <img src={img400} alt="Thumb" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about-area2 section-padding40w section-bg2 xs-padding-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-md-12">
              <div className="about-caption mb-50">
                <div className="about-icon">

                  <img src={abt} alt="Thumb" />
                </div>
                <div className="section-tittle mb-25">
                  <h2 className='text-black-40 text-left'>Types of application development</h2>
                  <h5>Mobile Application Development:</h5>
                </div>
                <p className="mb-20">
                  Native Apps: These are developed specifically for a single platform,
                  such as iOS (using Swift or Objective-C) or Android (using Java or
                  Kotlin). Native apps provide optimal performance and access to
                  device features.
                </p>
                <p className="mb-20">
                  Cross-Platform/Hybrid Apps: These apps are built using frameworks
                  like React Native, Flutter, or Xamarin. They allow developers to
                  write code once and deploy it on multiple platforms, balancing
                  development speed with performance.
                </p>
                <a href="about.html" className="all-btn">
                  Explore More
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-9 col-sm-9">
              <div className="about-img">
                <img src={img420} alt="Thumb" />

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area2 pb-bottom section-padding40 xs-padding-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-md-9">
              <div className="about-img ">
                <img src={img430} alt="Thumb" />
                <img src="assets/img/v-realty-1.png" alt="" />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 services-details-items plpr">
              <div className="about-caption mb-50">
         
                <div className="section-tittle mb-25">
                  <h2>Let's Transform Your Vision into Reality!</h2>
                </div>
                <p className="mb-20">
                  At Colate, we are passionate about turning ideas into functional,
                  reliable, and engaging applications. Our dedicated team of
                  developers and designers is ready to collaborate with you, ensuring
                  that your project is a resounding success.
                </p>
                <p>
                  Ready to take the next step? Contact us today to discuss your
                  project, receive a free quote, and embark on a journey to bring your
                  application to life!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <>
        {/* new section */}
        <section className="wantToWork-area default-padding1 pb-bottom">
          <div className="container">
            <div
              className="wants-wrapper w-padding2 section-bg2"
              style={divStyle}
            >
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-7 col-lg-9 col-md-8">
                  <div className="wantToWork-caption text-center">
                    <h2>Our Commitment to Excellence</h2>
                    <p>
                      At Colate, excellence is not just a goal – it's a commitment we
                      uphold in every project we undertake. Our dedicated team of
                      professionals is driven by a passion for innovation, a pursuit
                      of perfection, and a genuine desire to bring your ideas to life.
                      We strive to exceed your expectations at every stage of the
                      development process.
                    </p>
                    <a href="#" className="btn3 wantToWork-btn">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="services-details-area default-padding2 mt-5 pt300">
          <div className="container">
            <div className="services-details-items">
              <div className="row">
                <div className="col-lg-4 services-sidebar order-last order-lg-first mt50">
                  <img src={img450} alt="Thumb" />

                </div>
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Benefits of Partnering with Colate</span>
                 
                    <p>
                      Experience and Expertise: With a track record of successful
                      application development projects, we bring a wealth of experience
                      to the table. Our developers stay updated with the latest
                      technologies and trends to deliver cutting-edge solutions.
                    </p>
                    <p>
                      Transparent Communication: We believe in open and transparent
                      communication throughout the development process. Regular progress
                      updates and discussions ensure you're always in the loop and can
                      provide valuable input.
                    </p>
                    <p>
                      Scalability and Future-Proofing: Our applications are built with
                      scalability in mind. As your user base grows, your app will be
                      able to handle increased demand without compromising performance.
                    </p>
                    <p>
                      Data Security and Privacy: We prioritize the security of user data
                      and ensure that best practices for data protection are implemented
                      in the application. Your users' trust is of utmost importance to
                      us.
                    </p>
                    <p>
                      Cost-Efficiency: Our agile development approach allows us to work
                      efficiently and make adjustments as needed, helping you to
                      optimize development costs without sacrificing quality.
                    </p>
                    <p>
                      Client-Centric Approach: We view our clients as partners. Your
                      success is our success, and we're dedicated to helping you achieve
                      your goals through the applications we build.
                    </p>
              
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Our Commitment to Excellence</span>
                  <p>
                    Colate, excellence is not just a goal – it's a commitment we uphold
                    in every project we undertake. Our dedicated team of professionals
                    is driven by a passion for innovation, a pursuit of perfection, and
                    a genuine desire to bring your ideas to life. We strive to exceed
                    your expectations at every stage of the development process.
                  </p>
                </div>
                <div className="col-lg-4 services-sidebar">
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 services-sidebar order-last order-lg-first">
                  <img src={require('./../../../assets/img/illustration/automation-content-v2-info.svg').default} alt='mySvgImage' />
                </div>
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Seamless Integration of Features</span>
                  <p>
                    We understand that a successful application is built on the
                    foundation of seamless user experiences and robust features. Our
                    development approach focuses on integrating the right features in
                    the right way to ensure your application not only meets user needs
                    but also adds value to their lives. Whether it's interactive UI
                    elements, real-time notifications, secure payment gateways, or
                    intricate backend processes, we have the expertise to make it
                    happen.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Global Reach, Local Touch</span>
                  <p>
                    While we possess a global perspective and keep up with international
                    industry standards, we also embrace a local touch. We believe that
                    understanding the local culture, preferences, and behaviors of your
                    target audience is crucial to crafting applications that resonate
                    deeply. Our team takes pride in adapting global trends to suit your
                    local context, giving you an edge in your specific market.
                  </p>
                </div>
                <div className="col-lg-4 services-sidebar">
                  <img src={require('./../../../assets/img/illustration/aboutus-lead.svg').default} alt='mySvgImage' />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 services-sidebar">
                  <img src={require('./../../../assets/img/illustration/strategy-info.svg').default} alt='mySvgImage' />
                </div>
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Continuous Innovation</span>
                  <p>
                    The world of technology is ever-evolving, and at [Your Company
                    Name], we're committed to staying ahead of the curve. Our team
                    actively engages in research and development, exploring new tools,
                    frameworks, and methodologies to bring fresh ideas to your projects.
                    By blending innovation with tried-and-true practices, we ensure your
                    application not only meets current needs but also stands the test of
                    time.
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Unparalleled Customer Support</span>
                  <p>
                    Our relationship with you doesn't end with the launch of your
                    application. We provide ongoing support to address any queries,
                    concerns, or updates you may have. Our customer support team is just
                    a message away, ready to assist you with prompt responses and
                    effective solutions. Your success is our priority, and we're here to
                    help you every step of the way.
                  </p>
                </div>
                <div className="col-lg-4 services-sidebar">
                  <img src={require('./../../../assets/img/illustration/web3-thumb.svg').default} alt='mySvgImage' />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 services-sidebar">
                  <img src={require('./../../../assets/img/illustration/application-journey.svg').default} alt='mySvgImage' />
                </div>
                <div className="col-lg-8 services-single-content">
                  <span className="h2">Let's Begin Your Application Journey!</span>
                  <p>
                    Ready to turn your vision into a reality? Whether you're an
                    entrepreneur with a groundbreaking idea, a startup looking to make
                    its mark, or an established business seeking digital transformation,
                    Colateis here to help. Our application development expertise is the
                    key to unlocking your digital potential.
                  </p>
                  <p>
                    Connect with us today to schedule a consultation. Let's discuss your
                    project, explore possibilities, and create a roadmap that leads to a
                    remarkable application. Together, we'll navigate the dynamic world
                    of technology, crafting an application that not only meets your
                    expectations but exceeds them. Get in touch now and let the
                    transformation begin!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
}

export default VisionReality

