import { useEffect } from 'react'
import Footer from './Footer';
import Npp from '../../../assets/js/bootsnav';
import uniinfo from './../../../assets/img/stratagy_left.png';

function Strategy() {
 
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
      <Npp />
      <div className="about-area services-details-area default-padding1 pt176">
        <div className="container">
          <div className="services-details-items">
            <div className="row">
              <div className="col-lg-7 services-single-content content">
                <h4>Strategy</h4>
                <h1 className="mb-2 text-black-40 text-left">Our Strategy </h1>
                <p className="mb-2 text-black-40 text-left">Focus on continuous learning and adaptation</p>
                <p className="mb-2 text-black-40 text-left">
                  At Colate, we are dedicated to helping businesses navigate the complex landscape of strategy development and execution. We understand that in today's fast-paced and ever-evolving business world, having a well-defined and agile strategy is the key to success.
                </p>
                <p className="mb-2 text-black-40 text-left">
                  Our mission is to empower organizations of all sizes, from startups to enterprises, with the strategic insights and tools they need to thrive in an increasingly competitive market. We believe that strategy is not a one-time event but an ongoing process that requires continuous adaptation and improvement.
                </p>

              </div>
              <div className="col-md-4 col-lg-5 col-xs-12">
                <img src={uniinfo} />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="about-area overflow-hidden default-padding1 pt176">
        <section className="c-section">

          <ul className="c-services">
            <li className="c-services__item">
              <h3>Opportunities for Growth</h3>
              <p>
                By staying ahead of industry trends and customer needs, we will
                proactively identify opportunities for growth and expansion.
              </p>
            </li>
            <li className="c-services__item">
              <h3>Innovative Business Easy to customize</h3>
              <p>
                To ensure scalability and sustainability, we will carefully manage our
                resources, optimizing efficiency and cost-effectiveness. We will
                embrace lean principles and data-driven decision-making to prioritize
                investments and allocate resources strategically.
              </p>
            </li>
            <li className="c-services__item">
              <h3>Expertly Marketing High quality services</h3>
              <p>
                Collaboration and strategic partnerships will be key elements of our
                strategy. By forging alliances with complementary businesses, industry
                experts, and thought leaders, we will leverage their expertise and
                expand our reach. This approach will accelerate our growth, increase
                market penetration, and enhance our competitive advantage.
              </p>
            </li>
            <li className="c-services__item">
              <h3>Strong Focus</h3>
              <p>
                Finally, we will maintain a strong focus on continuous learning and
                adaptation. We will monitor market dynamics, anticipate trends, and be
                agile in responding to changes. Through ongoing innovation, iterative
                improvements, and a commitment to excellence, we will establish
                ourselves as a dynamic force in the market and create sustainable
                value for our customers, employees, and stakeholders.
              </p>
            </li>
            <li className="c-services__item">
              <h3>Mobile Apps Design</h3>
              <p>
                Our startup company's strategy is centered around innovation, agility,
                and market disruption. We will differentite ourselves by continuously
                challenging the status quo and embracing emerging technologies to
                deliver unique and compelling products and services. By staying ahead
                of industry trends and customer needs, we will proactively identify
                opportunities for growth and expansion.
              </p>
            </li>
            <li className="c-services__item">
              <h3>Expertly Marketing High quality services</h3>
              <p>
                It’s important to research deeply for the product you want to build.
                We help in that by defining the user audience, working on user
                stories, competitive analysis and much more.{" "}
              </p>
            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Strategy