import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
function ToastList() {
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [showC, setShowC] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);
  const toggleShowC = () => setShowC(!showC);
  return (
    <Row>
      <Col md={4} className="mb-2">
        <Button onClick={toggleShowA} className="mb-2">
        GitLab CI/CD with Auto DevOps
        </Button>
        <ToastContainer position="middle-center" className="p-3" style={{ zIndex: 1 }}>
        <Toast show={showA} onClose={toggleShowA}>
          <Toast.Header>
          <strong className="me-auto">Bootstrap</strong>
          <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
    
          </Toast.Header>
          <Toast.Body>GitLab's Auto DevOps leverages AI to automate the entire CI/CD pipeline, from code commit to deployment. It intelligently adjusts pipeline stages, tests, and infrastructure resources based on the code changes, ensuring efficient and optimized workflows.</Toast.Body>
        </Toast>
        </ToastContainer>
        <Button onClick={toggleShowB} className="mb-2">
          Toggle Toast <strong>without</strong> Animation
        </Button>
        <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1 }}>
        <Toast onClose={toggleShowB} show={showB} animation={true}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Bootstrap</strong>
        
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
        </ToastContainer>
        <Button onClick={toggleShowC} className="mb-2">
          Toggle Toast <strong>without</strong> Animation
        </Button>
        <ToastContainer position="bottom-start" className="p-3" style={{ zIndex: 1 }}>
        <Toast onClose={toggleShowC} show={showC} animation={true}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Bootstrap</strong>
        
          </Toast.Header>
          <Toast.Body className={'Dark'}>ccccccccccccccccWoohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
        </ToastContainer>
      </Col>
 

      <Col md={4} className="mb-2">
 
      </Col>
    </Row>
  );
}

export default ToastList;