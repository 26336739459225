import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { faDeleteLeft, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AdminBlogs.css';
import AddPage from './AddPage';

function AdminBlogs() {
    const [activeTabIndx, setActiveTabIndx] = useState(2);
    const [pageData, setPageData] = useState<any>([]);
    const handleDelete = (blog: any) => {
        const id = blog._id;
        axios.delete(`${process.env.REACT_APP_BASE_URL}/removepage?id=${id}`).then(res => {
            if (res.data) {
                getBlogs();
            }
            // setMessage(res.data.message);
            // setError(false);
        }).catch(e => {
            // setError(true);
        })
    }
    const handlePublish = (blog: any) => {

    }
    const getBlogs = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allPage`).then((res) => {
            if (res?.status === 200) {
                setPageData(res.data);
            }
        })
    }
    const handleTab = (data:number) => {
        console.log(data,'data');
        setActiveTabIndx(data)
    }
    useEffect(() => {
        getBlogs();
    }, [])
    return (
        <div>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            onClick={() => setActiveTabIndx(activeTabIndx === 1 ? 2 : 1)}
                            className={`accordion-button accorHeading ${activeTabIndx === 1 ? "active" : "collapsed"}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded={activeTabIndx === 1 ? "true" : "false"}
                            aria-controls="collapseOne">
                            {activeTabIndx === 1 ? <span className="btn btn-primary col-1">Cancel</span> : <span className="btn btn-primary col-1">Add</span>}
                        </button>
                    </h2>
                    <div id="collapseOne" className={activeTabIndx == 1 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                        <AddPage selectTab={handleTab} />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div id="collapseTwo" className={activeTabIndx == 2 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Author</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Created Date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageData.map((blg: any) => (
                                        <tr key={blg._id}>
                                            <td scope="row">{blg.title}</td>
                                            <td>{blg?.author?.[0]?.name}</td>
                                            <td>{blg?.category?.[0]?.name}</td>
                                            <td>{blg?.createdDate?.split('T')?.[0]}</td>
                                            <td>
                                                <FontAwesomeIcon className="actionIcon" onClick={() => handleDelete(blg)} icon={faTrash} />
                                                <FontAwesomeIcon className="actionIcon" onClick={() => handlePublish(blg)} icon={faUpload} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminBlogs