import { useEffect } from 'react';
import WOW from 'wowjs';
import Emergtech from './../../../assets/img/gallery/web3_option-3.png'
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer'
import img230 from './../../../assets/img/gallery/web3_square.png';
import img240 from './../../../assets/img/hero-img2.jpg';
import img250 from './../../../assets/img/gallery/generative-ai-shadow.png';
import img260 from './../../../assets/img/hero-img3.jpg';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';

function Web3() {
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
        <Seo title={seoData.Web3.title} description={seoData.Web3.description}/>
      <Npp />
      <div className="about-area2 section-padding40w pt176 section-bg2">
          <div className="container">
            <div className="about-items">
              <div className="row align-center">
                <div className="col-lg-5 col-md-5 offset-lg-0 wow bounceInLeft">
                  <div className="img-gradient1 img-gradient-right2">
                  <img
                    src={img250}
                    alt="Thumb"
                  />
                  </div>
                </div>
                {/* End Thumb */}
                <div className="col-lg-6 col-md-7 offset-lg-1 wow bounceInRight">
                  <div className="content pt-5 minusmargin107w">
                    {/* <h4>Web 3.0</h4> */}
                    <h1 className="mb-2 text-black-40 text-left">
                      Shift towards Web 3.0.{" "}
                    </h1>
                    <p>
                      We are experiencing a massive shift towards Web 3.0. Our team is
                      investing, focusing, and partnering with multiple companies to
                      develop applications.
                    </p>
                    <p>
                      Web 3.0 represents the next evolution of the internet, ushering
                      in a new era of decentralized, user-centric, and trust-enhancing
                      digital experiences. It goes beyond the traditional web by
                      leveraging blockchain technology, decentralized protocols, and
                      smart contracts to enable greater privacy, security, and data
                      ownership for individuals and businesses.
                    </p>
                    <p>
                      In Web 3.0, users have greater control over their data,
                      identities, and digital assets. They can securely interact with
                      online services, knowing that their personal information is
                      protected and that they have the ability to selectively share
                      data on their terms. This shift empowers individuals to actively
                      participate in the digital economy and regain sovereignty over
                      their online presence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area2 section-padding40 pt1761 section-bg2">
          <div className="container">
            <div className="about-items">
              <div className="row align-center">
       
                {/* End Thumb */}
                <div className="col-lg-6 col-md-7 offset-lg-0 wow bounceInUp">
                  <div className="content pt-5 minusmargin107">
                    {/* <h4>Web 3.0</h4> */}
                    <h3 className="mb-2 text-black-40 text-left">Satisfy your clients is everything</h3>
                  <p>
                    Decentralization is a fundamental principle of Web 3.0, where power
                    and decision-making are distributed among a network of peers rather
                    than centralized authorities. This decentralized architecture
                    reduces the risk of single points of failure, censorship, and data
                    breaches, fostering a more resilient and transparent internet
                    ecosystem.
                  </p>
                  <p>
                    Web 3.0 also embraces interoperability, allowing different
                    platforms, applications, and devices to seamlessly communicate and
                    share data. This interoperability drives innovation and enables the
                    creation of decentralized applications (dApps) that can leverage the
                    collective intelligence and resources of the network.
                  </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 offset-lg-1 wow bounceInDown">
                  <div className="img-gradient1 img-gradient-right2">
                  <img
                    src={img240}
                    alt="Thumb"
                  />
                  </div>
                </div>
              </div>

              <div className="row align-center">
       
       {/* End Thumb */}

       <div className="col-lg-5 col-md-5 offset-lg-0 wow bounceInUp">
         <div className="img-gradient1 img-gradient-right2">
         <img
           src={img260}
           alt="Thumb"
         />
         </div>
       </div>
       <div className="col-lg-6 col-md-7 offset-lg-1">
         <div className="content pt-5">
           {/* <h4>Web 3.0</h4> */}
           <h3 className="mb-2 text-black-40 text-left">Marketing</h3>
             
                  <p>
                    The advent of Web 3.0 opens up new possibilities for industries such
                    as finance, healthcare, supply chain, and governance. It facilitates
                    peer-to-peer transactions, automated agreements, and self-executing
                    contracts, eliminating intermediaries and reducing inefficiencies.
                    This transformative potential creates opportunities for increased
                    efficiency, cost savings, and inclusivity across various sectors.
                  </p>
                  <p>
                    At its core, Web 3.0 is about empowering individuals and fostering a
                    more democratic and equitable internet. It envisions a future where
                    users have greater agency, privacy, and security, while also
                    fostering innovation and collaboration on a global scale. As we
                    navigate the transition to Web 3.0, we have an opportunity to shape
                    a digital landscape that truly reflects the values of openness,
                    decentralization, and empowerment.
                  </p>
         </div>
       </div>

     </div>
            </div>
          </div>
        </div>


        <div className="about-area2 section-padding40 pt1761 section-bg2">
          <div className="container">
            <div className="about-items">
              <div className="row align-center">
       
                {/* End Thumb */}
                <div className="col-lg-6 col-md-7 offset-lg-0 wow bounceInDown">
                  <div className="content pt-5 minusmargin107">
                    {/* <h4>Web 3.0</h4> */}
                    <h3 className="mb-2 text-black-40 text-left mt-5">Satisfy your clients is everything</h3>
                    <p>
                      Web 3.0 has the potential to impact various industries by
                      introducing new technologies, business models, and opportunities.{" "}
                    </p>
                    <p>
                      Finance and Banking: Web 3.0 can disrupt the traditional financial
                      system by enabling decentralized finance (DeFi) applications.
                      Smart contracts and blockchain technology provide secure,
                      transparent, and efficient financial transactions, eliminating the
                      need for intermediaries and enabling peer-to-peer lending,
                      decentralized exchanges, and programmable money.
                    </p>
                    <p>
                      Supply Chain and Logistics: Web 3.0 can enhance transparency,
                      traceability, and efficiency in supply chain management. Through
                      blockchain technology, stakeholders can securely track and verify
                      the movement of goods, reduce fraud and counterfeiting, and
                      optimize supply chain operations.
                    </p>
                    <p>
                      Healthcare: Web 3.0 can transform healthcare by enabling secure
                      and interoperable health records, decentralized health data
                      exchanges, and patient-centric applications. It can enhance data
                      privacy, facilitate medical research, and empower individuals to
                      manage their health information more effectively.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 offset-lg-1 wow bounceInUp">
                  <div className="img-gradient1 img-gradient-right2">
                  <img
                    src={Emergtech}
                    alt="Thumb"
                  />
                  </div>
                </div>
              </div>

              <div className="row">

<div className="col-lg-11 col-md-11 mb-5 mt-5 wow bounceInLeft">
<p>
        Intellectual Property and Copyright: Web 3.0 introduces new models
        for protecting intellectual property and monetizing digital
        content. Smart contracts and blockchain technology enable artists,
        musicians, and creators to securely distribute and monetize their
        work directly to consumers, bypassing traditional intermediaries.
      </p>

      <p>
        Energy and Sustainability: Web 3.0 can facilitate peer-to-peer
        energy trading and decentralized energy management systems.
        Through blockchain technology and smart contracts, individuals and
        organizations can directly buy, sell, and trade renewable energy,
        promoting sustainability and reducing reliance on centralized
        energy providers.
      </p>
      <p>
        Governance and Voting Systems: Web 3.0 can enhance transparency
        and trust in governance systems by enabling decentralized voting
        mechanisms and citizen participation. Blockchain-based voting
        platforms can improve the integrity of elections and facilitate
        efficient decision-making processes.
      </p>
      <p>
        Education and e-Learning: Web 3.0 can revolutionize education by
        providing decentralized and personalized learning experiences.
        Smart contracts can facilitate secure credentialing and
        verification, while blockchain technology can enable the creation
        of decentralized educational content platforms and peer-to-peer
        learning networks.
      </p>

</div>
</div>

      
            </div>
          </div>
        </div>
      <Footer />
    </>
  )
}

export default Web3