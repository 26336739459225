import { useEffect } from 'react';
import Footer from './Footer';
import WOW from 'wowjs';
import Npp from '../../../assets/js/bootsnav'
import matrics1 from './../../../assets/img/devops-metrics-hub-lead.jpg';
import abt from './../../../assets//img/about.svg';
import img190 from './../../../assets/img/gallery/metrics_flat_1.png';
import img200 from './../../../assets/img/devops-reality-5.png';
import img210 from './../../../assets/img/devops_99.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import MatrixSlider from './MatrixSlider';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function DevopsMatrics() {
  const divStyle = {
        backgroundImage: `url(${img440})`
  }
  const Metrics = {
    backgroundImage: `url(${matrics1})`
  }
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
    <Seo title={seoData.DevopsMetrics.title} description={seoData.DevopsMetrics.description}/>
      <Npp />
      <section className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="row align-items-center1">
            <div className="col-lg-5 col-md-5 wow bounceInLeft">
              <div className="about-caption mb-50">
      
                <div className="section-tittle mb-25 content">
                  <h2 className='mb-2 text-black-40 text-left'>
                    
                    Harness the Power of DORA Metrics
                    for Engineering Excellence
                  </h2>
                  <h3 className="mt-5 mb-2 text-black-40 text-left">
                    Drive Your DevOps Success with Metrics that Matter
                  </h3>
                  <p className='mb-2 text-black-40 text-left mt-5'>
                    Are you striving to achieve engineering excellence in your DevOps
                    journey? Do you need a reliable way to measure and improve your
                    software delivery performance? Look no further! The DevOps Metrics
                    Hub is your one-stop destination for adopting and leveraging the
                    renowned DORA metrics to elevate your engineering practices.
                  </p>
                  <h3 className='mb-2 mt-5 text-black-40 text-left'>What are DORA Metrics?</h3>
                  <p className='mb-2 text-black-40 text-left'>
                      DORA (DevOps Research and Assessment) metrics are a set of key
                      performance indicators (KPIs) developed by the DORA research team
                      to evaluate and enhance software delivery performance.
                  </p>
                  <p>
                      These metrics are derived from years of empirical research and are
                      widely recognized as the gold standard for measuring DevOps
                      success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 offset-lg-1 col-xs-12 wow bounceInRight">
              <div className="about-img">
                <img src={img190} alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="case-studies-area default-padding-bottom">

<MatrixSlider />
</div>



      <section className="wantToWork-area default-padding ">
          <div className="container">
            <div
              className="wants-wrapper w-padding2 section-bg2"
              style={divStyle}
            >
              <div className="row align-items-center justify-content-center wow bounceInDown">
                <div className="col-xl-7 col-lg-9 col-md-8">
                  <div className="wantToWork-caption text-center">
                    <h2>Elevate Your Engineering Practices Today</h2>
                    <p>
                      Unlock the potential of your DevOps journey with the power of DORA metrics.
                      Join leading organizations that have achieved engineering excellence by adopting data-driven practices.
                      Sign up with us and witness the transformation in your software delivery performance.
                    </p>
                    <a href="/Contact" className="btn3 wantToWork-btn">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
      </section>
      <Footer />
    </>
  )
}

export default DevopsMatrics