import { useEffect } from 'react';
import WOW from 'wowjs';
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import telecommunication1 from './../../../assets/img/telecommunication-lead.jpg';
import img220 from './../../../assets/img/gallery/telecomunication_prof.png';
import img230 from './../../../assets/img/network-solution-thumb.png';
import uniinfo from './../../../assets/img/gallery/mission_gray-1.png';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Telecommunication() {
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);

  return (
    <>
        <Seo title={seoData.Telecommunication.title} description={seoData.Telecommunication.description}/>
      <Npp />
      <div className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-6 col-md-5 wow bounceInLeft">
                <div className="content">
                  {/* <h4>Telecommunication</h4> */}
                  <h1 className="mb-2 text-black-40 text-left">Telecommunication Services</h1>
                  <h5 className="mb-2 text-black-40 text-left">
                    We are dedicated to connecting the world
                  </h5>
                  <p className="text-black-40 mt-3">
                    Welcome to our telecommunications group, where we are dedicated to
                    connecting the world through innovative solutions and
                    state-of-the-art technology.
                  </p>
                  <p className="text-black-40 mt-3">
                    At our core, we believe that communication is key to human
                    progress and that everyone deserves access to reliable,
                    high-quality telecommunications services. That's why we are
                    committed to developing cutting-edge products and services that
                    empower individuals and organizations to stay connected, no matter
                    where they are in the world.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 offset-lg-1 col-xs-12 wow bounceInRight">
                <div className="thumb">
                <img style={{verticalAlign:'middle'}}
                  src={require('./../../../assets/img/gallery/5g_square.png') }
                  alt="Thumb"/>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area2 section-padding40w pt176w section-bg2">
        <div className="container">
          <div className="inner-items">
            <div className="row mt50w">
    
              <div className="col-lg-11 col-md-11 offset-lg-0 wow bounceInDown">
                <div className="info3 hero_content">
                  <h2 />
                  <h1 className="mb-2 text-black-40 text-left">Network solutions</h1>
                  <p className="text-black-40 mt-3">
                    Whether you're looking for voice and data services, network
                    solutions, or IoT and machine-to-machine communication
                    capabilities, we have the expertise and technology to meet your
                    needs. From mobile and landline services to cloud-based
                    communication solutions and beyond, we offer a wide range of
                    products and services to help you stay connected and reach your
                    full potential.
                  </p>
                  <p className="text-black-40 mt-3">
                    But more than just a provider of telecommunications solutions, we
                    are a company that's deeply committed to our customers and
                    partners. We believe that collaboration, innovation, and a
                    relentless focus on customer satisfaction are essential to success
                    in the telecommunications industry, and we work closely with our
                    clients to develop tailored solutions that meet their unique needs
                    and help them achieve their goals.
                  </p>
                  <p className="text-black-40 mt-3">
                    As we continue to innovate and develop new products and services,
                    we remain dedicated to our core values of connectivity,
                    innovation, and customer service. We are excited to be at the
                    forefront of the telecommunications industry, and we look forward
                    to helping you stay connected and reach your full potential, no
                    matter where you are in the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area2 section-padding40w pt176w section-bg2">
        <div className="container">
          <div className="testimonial-items text-center1">
            <div className="row">
  
              <div className="col-lg-6 col-md-6 offset-lg-0 wow bounceInUp">
                <div className="heading  mt-5 hero_content">
                  <p className="mb-2 text-black-40 text-left">
                    Our telecommunications solutions are designed to be fast,
                    reliable, and secure. We understand the importance of staying
                    connected in today's fast-paced world, and we are committed to
                    providing our customers with the highest quality service possible.
                    Whether you're using our mobile services to stay connected on the
                    go or our cloud-based solutions to collaborate with team members
                    across the globe, you can trust that your communications are in
                    good hands.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    In addition to our products and services, we also offer expert
                    support and guidance to help you get the most out of your
                    telecommunications solutions. Our team of experienced
                    professionals is always available to answer your questions,
                    troubleshoot any issues, and provide advice on how to optimize
                    your telecommunications strategy.
                  </p>
                
                  <p />
            
                </div>
              </div>

              <div className="col-md-6 col-lg-4 offset-lg-1 col-xs-12">
                <img style={{verticalAlign:'middle',marginTop:'40px'}}
                  src={require('./../../../assets/img/gallery/gray_5g.png') }
                  alt="Thumb"
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area2 section-padding40w pt176w section-bg2">
        <div className="container">
          <div className="testimonial-items text-center1 hero_content wow bounceInDown">
            <div className="row">
            <h1 className="mb-2 text-black-40 text-left">5G Network</h1>
              <div className="col-lg-6 col-md-6 offset-lg-0 ">
                <div className="heading">
              
             
                  <p className="mb-2 text-black-40 text-left">
                    We also understand the importance of staying ahead of the curve in
                    an ever-evolving industry. That's why we are constantly investing
                    in research and development to stay at the forefront of the latest
                    telecommunications trends and technologies. From 5G networks and
                    edge computing to artificial intelligence and blockchain, we are
                    committed to developing the solutions of tomorrow to meet the
                    needs of our customers today.
                  </p>
                  <p />
                  <p className="mb-2 text-black-40 text-left">
                    At our core, we believe that telecommunications is about more than
                    just making phone calls or sending messages. It's about staying
                    connected to the people and things that matter most, and about
                    using technology to improve our lives and the world around us. We
                    are proud to be a part of this important industry, and we look
                    forward to helping you stay conimport Services from './Services';
                    nected and achieve your goals.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 offset-lg-2 col-xs-12">
                <img style={{verticalAlign:'middle'}}
                  src={require('./../../../assets/img/gallery/app_devlopment_500-700.png') }
                  alt="Thumb"
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Telecommunication