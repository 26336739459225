import { useEffect } from 'react';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import cocreate1 from './../../../assets/img/gallery/cocrate1.jpg';
import cocreate2 from './../../../assets/img/gallery/all-in-one.jpg';
import cloudlogos from './../../../assets/img/gallery/cloud-logos.png';
import onprem from './../../../assets/img/gallery/onprem-logo.png';
import applicationlogo from './../../../assets/img/gallery/application-logos.png';

import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/stratagy_left.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
function Signup() {
  const divStyle = {
      backgroundImage: `url(${img440})`
  }

 
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>
<Npp/>
      <section className="about-area2 section-padding40w pt176 section-bg2 cocreate">
      <div className="container header-eight">
      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12 col-12 text-center">
          <div className="header-contentw">
   
            <div >
         <h1 className='whitecolor'>Coming Soon</h1>

         <p><Link to="/Cocreate">Click here Back to CoCreate</Link></p>
            </div>
    
   
       
         
          </div>
        </div>
 
      </div>
    </div>
      </section>

  

  <Footer/>
    </>
  )
}

export default Signup