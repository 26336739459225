import { useEffect } from 'react';
import WOW from 'wowjs';
import Npp from '../../../assets/js/bootsnav';
import Footer from './Footer'
import img200 from './../../../assets/img/gallery/fintect_tech_gray.png';
import abt from './../../../assets//img/about.svg';
import img210 from './../../../assets/img/fintect_bottom.png';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Fintech() {

  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);

  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>
    <Seo title={seoData.Fintech.title} description={seoData.Fintech.description}/>
      <Npp />
      <div className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-6 col-md-5 wow bounceInLeft">
                <div className="about-caption mb-50">
        
                  <div className="section-tittle mb-25">
                    <h2 className="mb-2 text-black-40 text-left">Welcome to our fintech product &amp; services offering</h2>
                    <p className="mt-5 mb-2 text-black-40 text-left">
                      where we are dedicated to revolutionizing the world of finance
                      through innovative solutions and cutting-edge technology.
                    </p>
                    <p className="mb-2 text-black-40 text-left">
                      we believe that everyone deserves access to secure, reliable
                      financial services that help them achieve their goals and
                      realize their dreams. That's why we are committed to developing
                      state-of-the-art products and services that empower individuals
                      and organizations to take control of their finances and achieve
                      financial freedom.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 offset-lg-1 col-xs-12 wow bounceInRight">
                <div className="thumb ">
                  <img
                    src={img200}
                    alt="Thumb"
                  />
                </div>
              </div>
         
              <div className="col-lg-12 col-md-12 offset-lg-0 mt-5 wow bounceInDown">
                <div className="content">
              
                  <p className="mb-2 text-black-40 text-left">
                    Whether you're an individual looking to manage your finances more
                    effectively or a business seeking to streamline your financial
                    operations, we have the expertise and technology to help you
                    succeed. From digital banking solutions and payment processing
                    services to investment management tools and financial education
                    resources, we offer a wide range of products and services to meet
                    your needs.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    But more than just a provider of financial solutions, we are a
                    company that's deeply committed to our customers and partners. We
                    believe that trust, transparency, and collaboration are essential
                    to success in the financial industry, and we work closely with our
                    clients to develop tailored solutions that meet their unique needs
                    and help them achieve their goals.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    As we continue to innovate and develop new products and services,
                    we remain dedicated to our core values of trust, transparency, and
                    empowerment. We are excited to be at the forefront of the fintech
                    revolution, and we look forward to helping you achieve your
                    financial goals and dreams.
                  </p>
                  <p className="mb-22 text-black-40 text-left mbpara">
                    The leadership team has more than 20+ industry experience in
                    financial services and was part of ground breaking technology
                    shifts that happened in last two decades. The team poses a solid
                    technical expertise to make the your product a success story.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Fintech