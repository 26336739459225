import Npp from '../../../assets/js/bootsnav';
import Footer from './Footer';
import img700 from './../../../assets/img/strategy-lead.jpg';
import img800 from './../../../assets/img/blockchain-lead.jpg';
import img850 from './../../../assets/img/emerging-technologies-lead.jpeg';
import img350 from './../../../assets/img/gallery/app-dev-ser.jpg.png';
import img950 from './../../../assets/img/gallery/services_1.jpg';
import ServicesSwiper from './ServicesSwiper';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';

function Services() {
  return (
    <>
      <Npp />
      <Seo title={seoData.services.title} description={seoData.services.description}/>
<section className="about-area section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className="about-caption mb-50">
                <div className="section-tittle mb-25 content">
                  <h2 className='mb-2 text-black-40 text-left'>
                    Elevate your business with our comprehensive services
                  </h2>
                  <p>
                    At Colate, we offer a comprehensive range of services tailored to meet your business needs. Our dedicated team of experts is committed to delivering top-notch solutions to help you achieve your goals efficiently and effectively. Explore our services below:
                  </p>
                  <p>
                    Navigating the complex landscape of IT requires strategic planning and expert guidance. Our IT Consultation and Strategy services offer insights and solutions to align your IT initiatives with your business objectives. Leverage our expertise to make informed decisions, optimize your IT resources, and gain a competitive edge in your industry.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-5 col-xl-5'>      <img
                    src={img950}
                    alt="Thumb"
                  /></div>
          </div>
        </div>
      </section>

      <section className="topics-section section-padding pb-5" id="section_3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="section-title-wrap mb-5">
                <h4 className="section-title">Our Services</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="custom-block custom-block-overlay">
                <a href="detail-page.html" className="custom-block-image-wrap">
                  <img
                    src={img700}
                    alt="Thumb"
                  />
                </a>
                <div className="custom-block-info custom-block-overlay-info">
                  <h5 className="mb-1">
                    <a href="/CloudInfrastructure">
                      <p>Cloud Infrastructure Automation</p>
                      <p className="badge mb-0 mt-5">Read More</p>
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="custom-block custom-block-overlay">
                <a href="detail-page.html" className="custom-block-image-wrap">
                  <img
                    src={img800}
                    alt="Thumb"
                  />
                </a>
                <div className="custom-block-info custom-block-overlay-info">
                  <h5 className="mb-1">
                    <a href="/DevopsAutomation">
                      <p>End-To-End DevOps Automation</p>
                      <p className="badge mb-0 mt-5">Read More</p>
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="custom-block custom-block-overlay">
                <a href="detail-page.html" className="custom-block-image-wrap">
                  <img
                    src={img850}
                    alt="Thumb"
                  />
                </a>
                <div className="custom-block-info custom-block-overlay-info">
                  <h5 className="mb-1">
                    <a href="/DevopsMetrics">
                      <p>Engineering Efficiency Measurement</p>
                      <p className="badge mb-0 mt-5">Read More</p>
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="custom-block custom-block-overlay">
                <a href="detail-page.html" className="custom-block-image-wrap">
                  <img
                    src={img350}
                    alt="Thumb"
                  />
                </a>
                <div className="custom-block-info custom-block-overlay-info">
                  <h5 className="mb-1">
                    <a href="/ApplicationDevlopment">
                      <p>Application Development</p>
                      <p className="badge mb-0 mt-5">Read More</p>
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="latest-podcast-section default-padding" id="section_2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-12">
              <div className="section-title-wrap mb-5">
                <h4 className="section-title">Our Industires</h4>
              </div>
            </div>
            <div className="col-lg-3 col-12 mb-4 mb-lg-0">
              <div className="d-flex">
                <h3>Fintech</h3>
              </div>
              <div className="custom-block d-flex mt-5">
                <a href="/Fintech">
                  <p>We are dedicated to revolutionizing the world of finance through innovative solutions.</p>
                  <p className="badge mb-0 mt-5">Read More</p>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="d-flex">
                <h3>Telecommunication</h3>
              </div>
              <div className="custom-block d-flex mt-5">
                <a href="/Telecommunication">
                  <p>We are dedicated to connecting the world through innovative solutions and state-of-the-art technology.</p>
                  <p className="badge mb-0 mt-5">Read More</p>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="d-flex">
                <h3>Healthcare</h3>
              </div>
              <div className="custom-block d-flex mt-5">
                <a href="/Healthcare">
                  <p>we are dedicated to improving lives through innovative healthcare solutions and compassionate care.</p>
                  <p className="badge mb-0 mt-5">Read More</p>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="d-flex">
                <h3>Hardware Engineering</h3>
              </div>
              <div className="custom-block d-flex mt-5">
                <a href="/HardwareEngineering">
                  <p>we are dedicated to improving lives through innovative healthcare solutions and compassionate care.</p>
                  <p className="badge mb-0 mt-5">Read More</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Services
