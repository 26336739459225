import { useEffect } from 'react'
import WOW from 'wowjs';
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import matrics1 from './../../../assets/img/devops-metrics-hub-lead.jpg';
import abt from './../../../assets//img/about.svg';
import img190 from './../../../assets/img/gallery/generative-ai-shadow.png';
import img200 from './../../../assets/img/devops-reality-5.png';
import img210 from './../../../assets/img/devops_99.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import ClodInfraSlider from './ClodInfraSlider';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function DevopsMatrics() {
const divStyle = {
backgroundImage: `url(${img440})`
}

const Metrics = {
backgroundImage: `url(${matrics1})`
}

useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
useEffect(() => {
document.body.classList.remove('dark-layout');
}, []);
return (
<>
<Seo title={seoData.cloudInfrastructure.title} description={seoData.cloudInfrastructure.description}/>
<Npp />
<section className="about-area2 section-padding40w pt176 section-bg2">
<div className="container">
<div className="row align-items-center1">
<div className="col-lg-6 col-md-5 wow bounceInRight">
<div className="about-caption mb-50">
<div className="section-tittle mb-25">
<h1 className="mb-2 text-black-40 text-left">Cloud Infrastructure Simplified</h1>

<p className="text-black-40 mt-5">
Streamline your cloud infrastructure and elevate your DevOps with Colate's Infrastructure
Automation solutions. Embrace innovation while keeping costs low and productivity high.
</p>

<p className="text-black-40 mt-3 ">
At Colate, we understand the complexities of managing cloud infrastructure. We tackle the most pressing challenges head-on, ensuring your business operates flawlessly in the cloud.
</p>
</div>
</div>
</div>
<div className="col-md-6 col-lg-5 offset-lg-1 col-xs-12 wow bounceInLeft">
<div className="about-img mt502">
<img src={img190} alt="Thumb" />
</div>
</div>
</div>
</div>
</section>

<div className="case-studies-area default-padding-bottom">

<ClodInfraSlider />
</div>


<section className="wantToWork-area default-padding ">
<div className="container">
<div
className="wants-wrapper w-padding2 section-bg2"
style={divStyle}
>
<div className="row align-items-center justify-content-center">
<div className="col-xl-7 col-lg-9 col-md-8">
<div className="wantToWork-caption text-center">
<h2>Let's Transform Your Infrastructure Together</h2>
<p>
With Colate's cloud solutions, your business logic takes the spotlight, while we manage the cloud intricacies silently in the background. Experience the freedom to innovate, grow, and succeed without cloud worries. Let's elevate your business together.
</p>
<a href="/Contact" className="btn3 wantToWork-btn">
Contact Us
</a>
</div>
</div>
</div>
</div>
</div>
<br/>
<br/>
<br/>
<br/>
</section>
<Footer />
</>
)
}

export default DevopsMatrics