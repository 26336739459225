
import React from 'react';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Link } from "react-router-dom"
// the $ sign is used to select DOM elements in jQuery
import $ from 'jquery';
import './../css/bootsnav.css'
import Uniqueness from '../../pages/UI/common/Uniqueness';
import WOW from 'wowjs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
// ------------------------------------------------------------------------------ //
//
// Template name : Bootsnav - Multi Purpose Header
// Categorie : Bootstrap Menu in CSS
// Author : adamnurdin01
// Version : v.1.2
// Created : 2016-06-02
// Last update : 2016-10-19
//
// ------------------------------------------------------------------------------ //
export default class Npp extends React.Component {
// function to run when app is mounted
componentDidMount() {


$(document).ready(function () {
"use strict";

var bootsnav = {
initialize: function () {
this.event();
this.hoverDropdown();
this.navbarSticky();
this.navbarScrollspy();
},
event: function () {

// ------------------------------------------------------------------------------ //
// Variable
// ------------------------------------------------------------------------------ //
var getNav = $("nav.navbar.bootsnav");

// ------------------------------------------------------------------------------ //
// Navbar Sticky 
// ------------------------------------------------------------------------------ //
var navSticky = getNav.hasClass("navbar-sticky");
if (navSticky) {
// Wraped navigation
getNav.wrap("<div class='wrap-sticky'></div>");
}

// ------------------------------------------------------------------------------ //
// Navbar Center 
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("brand-center")) {
var postsArr = new Array(),
index = $("nav.brand-center"),
$postsList = index.find('ul.navbar-nav');

index.prepend("<span class='storage-name' style='display:none;'></span>");

//Create array of all posts in lists
index.find('ul.navbar-nav > li').each(function () {
if ($(this).hasClass("active")) {
var getElement = $("a", this).eq(0).text();
$(".storage-name").html(getElement);
}
postsArr.push($(this).html());
});

//Split the array at this point. The original array is altered.
var firstList = postsArr.splice(0, Math.round(postsArr.length / 2)),
secondList = postsArr,
ListHTML = '';

var createHTML = function (list) {
ListHTML = '';
for (var i = 0; i < list.length; i++) {
ListHTML += '<li>' + list[i] + '</li>'
}
}

//Generate HTML for first list
createHTML(firstList);
$postsList.html(ListHTML);
index.find("ul.nav").first().addClass("navbar-left");

//Generate HTML for second list
createHTML(secondList);
//Create new list after original one
$postsList.after('<ul class="nav navbar-nav"></ul>').next().html(ListHTML);
index.find("ul.nav").last().addClass("navbar-right");

//Wrap navigation menu
index.find("ul.nav.navbar-left").wrap("<div class='col-half left'></div>");
index.find("ul.nav.navbar-right").wrap("<div class='col-half right'></div>");

//Selection Class
index.find('ul.navbar-nav > li').each(function () {
var dropDown = $("ul.dropdown-menu", this),
megaMenu = $("ul.megamenu-content", this);
dropDown.closest("li").addClass("dropdown");
megaMenu.closest("li").addClass("megamenu-fw");
});

var getName = $(".storage-name").html();
if (!getName == "") {
$("ul.navbar-nav > li:contains('" + getName + "')").addClass("active");
}
}


// ------------------------------------------------------------------------------ //
// Navbar Sidebar 
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("navbar-sidebar")) {
// Add Class to body
$("body").addClass("wrap-nav-sidebar");
getNav.wrapInner("<div class='scroller'></div>");
} else {
$(".bootsnav").addClass("on");
}

// ------------------------------------------------------------------------------ //
// Menu Center 
// ------------------------------------------------------------------------------ //
if (getNav.find("ul.nav").hasClass("navbar-center")) {
getNav.addClass("menu-center");
}

// ------------------------------------------------------------------------------ //
// Navbar Full
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("navbar-full")) {
// Add Class to body
$("nav.navbar.bootsnav").find("ul.nav").wrap("<div class='wrap-full-menu'></div>");
$(".wrap-full-menu").wrap("<div class='nav-full'></div>");
$("ul.nav.navbar-nav").prepend("<li class='close-full-menu'><a href='#'><i class='fa fa-times'></i></a></li>");
} else if (getNav.hasClass("navbar-mobile")) {
getNav.removeClass("no-full");
} else {
getNav.addClass("no-full");
}

// ------------------------------------------------------------------------------ //
// Navbar Mobile
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("navbar-mobile")) {
// Add Class to body
$('.navbar-collapse').on('shown.bs.collapse', function () {
$("body").addClass("side-right");
});
$('.navbar-collapse').on('hide.bs.collapse', function () {
$("body").removeClass("side-right");
});

$(window).on("resize", function () {
$("body").removeClass("side-right");
});
}

// ------------------------------------------------------------------------------ //
// Navbar Fixed
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("no-background")) {
$(window).on("scroll", function () {
var scrollTop = $(window).scrollTop();
if (scrollTop > 34) {
$(".navbar-fixed").removeClass("no-background");
} else {
$(".navbar-fixed").addClass("no-background");
}
});
}

// ------------------------------------------------------------------------------ //
// Navbar Fixed
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("navbar-transparent")) {
$(window).on("scroll", function () {
var scrollTop = $(window).scrollTop();
if (scrollTop > 34) {
$(".navbar-fixed").removeClass("navbar-transparent");
} else {
$(".navbar-fixed").addClass("navbar-transparent");
}
});
}

// ------------------------------------------------------------------------------ //
// Button Cart
// ------------------------------------------------------------------------------ //
$(".btn-cart").on("click", function (e) {
e.stopPropagation();
});

// ------------------------------------------------------------------------------ //
// Toggle Search
// ------------------------------------------------------------------------------ //
$("nav.navbar.bootsnav .attr-nav").each(function () {
$("li.search > a", this).on("click", function (e) {
e.preventDefault();
$(".top-search").slideToggle();
});
});
$(".input-group-addon.close-search").on("click", function () {
$(".top-search").slideUp();
});

// ------------------------------------------------------------------------------ //
// Toggle Side Menu
// ------------------------------------------------------------------------------ //
$("nav.navbar.bootsnav .attr-nav").each(function () {
$("li.side-menu > a", this).on("click", function (e) {
e.preventDefault();
$("nav.navbar.bootsnav > .side").toggleClass("on");
$("body").toggleClass("on-side");
});
});
$(".side .close-side").on("click", function (e) {
e.preventDefault();
$("nav.navbar.bootsnav > .side").removeClass("on");
$("body").removeClass("on-side");
});



// ------------------------------------------------------------------------------ //
// Wrapper
// ------------------------------------------------------------------------------ //
$("body").wrapInner("<div class='wrapper'></div>");
},


// ------------------------------------------------------------------------------ //
// Change dropdown to hover on dekstop
// ------------------------------------------------------------------------------ //
hoverDropdown: function () {
var getNav = $("nav.navbar.bootsnav"),
getWindow = $(window).width(),
getHeight = $(window).height(),
getIn = getNav.find("ul.nav").data("in"),
getOut = getNav.find("ul.nav").data("out");

if (getWindow < 991) {

// Height of scroll navigation sidebar
$(".scroller").css("height", "auto");

// Disable mouseenter event
$("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseenter");
$("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseleave");
$("nav.navbar.bootsnav ul.nav").find(".title").off("mouseenter");
$("nav.navbar.bootsnav ul.nav").off("mouseleave");
$(".navbar-collapse").removeClass("animated");

// Enable click event
$("nav.navbar.bootsnav ul.nav").each(function () {
$(".dropdown-menu", this).addClass("animated");
$(".dropdown-menu", this).removeClass(getOut);

// Dropdown Fade Toggle
$("a.dropdown-toggle", this).off('click');
$("a.dropdown-toggle", this).on('click', function (e) {
e.stopPropagation();
$(this).closest("li.dropdown").find(".dropdown-menu").first().stop().fadeToggle().toggleClass(getIn);
$(this).closest("li.dropdown").first().toggleClass("on");
return false;
});

// Hidden dropdown action
$('li.dropdown', this).each(function () {
$(this).find(".dropdown-menu").stop().fadeOut();
$(this).on('hidden.bs.dropdown', function () {
$(this).find(".dropdown-menu").stop().fadeOut();
});
return false;
});

// Megamenu style
$(".megamenu-fw", this).each(function () {
$(".col-menu", this).each(function () {
$(".content", this).addClass("animated");
$(".content", this).stop().fadeOut();
$(".title", this).off("click");
$(".title", this).on("click", function () {
$(this).closest(".col-menu").find(".content").stop().fadeToggle().addClass(getIn);
$(this).closest(".col-menu").toggleClass("on");
return false;
});

$(".content", this).on("click", function (e) {
e.stopPropagation();
});
});
});
});

// Hidden dropdown
var cleanOpen = function () {
$('li.dropdown', this).removeClass("on");
$(".dropdown-menu", this).stop().fadeOut();
$(".dropdown-menu", this).removeClass(getIn);
$(".col-menu", this).removeClass("on");
$(".col-menu .content", this).stop().fadeOut();
$(".col-menu .content", this).removeClass(getIn);
}

// Hidden om mouse leave
$("nav.navbar.bootsnav").on("mouseleave", function () {
cleanOpen();
});

// Enable click atribute navigation
$("nav.navbar.bootsnav .attr-nav").each(function () {
$(".dropdown-menu", this).removeClass("animated");
$("li.dropdown", this).off("mouseenter");
$("li.dropdown", this).off("mouseleave");
$("a.dropdown-toggle", this).off('click');
$("a.dropdown-toggle", this).on('click', function (e) {
e.stopPropagation();
$(this).closest("li.dropdown").find(".dropdown-menu").first().stop().fadeToggle();
$(".navbar-toggle").each(function () {
$(".fa", this).removeClass("fa-times");
$(".fa", this).addClass("fa-bars");
$(".navbar-collapse").removeClass("in");
$(".navbar-collapse").removeClass("on");
});
});

$(this).on("mouseleave", function () {
$(".dropdown-menu", this).stop().fadeOut();
$("li.dropdown", this).removeClass("on");
return false;
});
});

// Toggle Bars
$(".navbar-toggle").each(function () {
$(this).off("click");
$(this).on("click", function () {
$(".fa", this).toggleClass("fa-bars");
$(".fa", this).toggleClass("fa-times");
cleanOpen();
});
});

} else if (getWindow > 991) {
// Height of scroll navigation sidebar
$(".scroller").css("height", getHeight + "px");

// Navbar Sidebar
if (getNav.hasClass("navbar-sidebar")) {
// Hover effect Sidebar Menu
$("nav.navbar.bootsnav ul.nav").each(function () {
$("a.dropdown-toggle", this).off('click');
$("a.dropdown-toggle", this).on('click', function (e) {
e.stopPropagation();
});

$(".dropdown-menu", this).addClass("animated");
$("li.dropdown", this).on("mouseenter", function () {
$(".dropdown-menu", this).eq(0).removeClass(getOut);
$(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
$(this).addClass("on");
return false;
});

$(".col-menu").each(function () {
$(".content", this).addClass("animated");
$(".title", this).on("mouseenter", function () {
$(this).closest(".col-menu").find(".content").stop().fadeIn().addClass(getIn);
$(this).closest(".col-menu").addClass("on");
return false;
});
});

$(this).on("mouseleave", function () {
$(".dropdown-menu", this).stop().removeClass(getIn);
$(".dropdown-menu", this).stop().addClass(getOut).fadeOut();
$(".col-menu", this).find(".content").stop().fadeOut().removeClass(getIn);
$(".col-menu", this).removeClass("on");
$("li.dropdown", this).removeClass("on");
return false;
});
});
} else {
// Hover effect Default Menu
$("nav.navbar.bootsnav ul.nav").each(function () {
$("a.dropdown-toggle", this).off('click');
$("a.dropdown-toggle", this).on('click', function (e) {
e.stopPropagation();
});

$(".megamenu-fw", this).each(function () {
$(".title", this).off("click");
$("a.dropdown-toggle", this).off("click");
$(".content").removeClass("animated");
});

$(".dropdown-menu", this).addClass("animated");
$("li.dropdown", this).on("mouseenter", function () {
$(".dropdown-menu", this).eq(0).removeClass(getOut);
$(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
$(this).addClass("on");
return false;
});

$("li.dropdown", this).on("mouseleave", function () {
$(".dropdown-menu", this).eq(0).removeClass(getIn);
$(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
$(this).removeClass("on");
});

$(this).on("mouseleave", function () {
$(".dropdown-menu", this).removeClass(getIn);
$(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
$("li.dropdown", this).removeClass("on");
return false;
});
});
}

// ------------------------------------------------------------------------------ //
// Hover effect Atribute Navigation
// ------------------------------------------------------------------------------ //
$("nav.navbar.bootsnav .attr-nav").each(function () {
$("a.dropdown-toggle", this).off('click');
$("a.dropdown-toggle", this).on('click', function (e) {
e.stopPropagation();
});

$(".dropdown-menu", this).addClass("animated");
$("li.dropdown", this).on("mouseenter", function () {
$(".dropdown-menu", this).eq(0).removeClass(getOut);
$(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
$(this).addClass("on");
return false;
});

$("li.dropdown", this).on("mouseleave", function () {
$(".dropdown-menu", this).eq(0).removeClass(getIn);
$(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
$(this).removeClass("on");
});

$(this).on("mouseleave", function () {
$(".dropdown-menu", this).removeClass(getIn);
$(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
$("li.dropdown", this).removeClass("on");
return false;
});
});
}

// ------------------------------------------------------------------------------ //
// Menu Fullscreen
// ------------------------------------------------------------------------------ //
if (getNav.hasClass("navbar-full")) {
var windowHeight = $(window).height(),
windowWidth = $(window).width();

$(".nav-full").css("height", windowHeight + "px");
$(".wrap-full-menu").css("height", windowHeight + "px");
$(".wrap-full-menu").css("width", windowWidth + "px");

$(".navbar-collapse").addClass("animated");
$(".navbar-toggle").each(function () {
var getId = $(this).data("target");
$(this).off("click");
$(this).on("click", function (e) {
e.preventDefault();
$(getId).removeClass(getOut);
$(getId).addClass("in");
$(getId).addClass(getIn);
return false;
});

$("li.close-full-menu").on("click", function (e) {
e.preventDefault();
$(getId).addClass(getOut);
setTimeout(function () {
$(getId).removeClass("in");
$(getId).removeClass(getIn);
}, 500);
return false;
});
});
}
},

// ------------------------------------------------------------------------------ //
// Navbar Sticky
// ------------------------------------------------------------------------------ //
navbarSticky: function () {
var getNav = $("nav.navbar.bootsnav"),
navSticky = getNav.hasClass("navbar-sticky");

if (navSticky) {

// Set Height Navigation
var getHeight = getNav.height();
$(".wrap-sticky").height(getHeight);

// Windown on scroll
var getOffset = $(".wrap-sticky").offset().top;
$(window).on("scroll", function () {
var scrollTop = $(window).scrollTop();
if (scrollTop > getOffset) {
getNav.addClass("sticked");
} else {
getNav.removeClass("sticked");
}
});
}
},

// ------------------------------------------------------------------------------ //
// Navbar Scrollspy
// ------------------------------------------------------------------------------ //
navbarScrollspy: function () {
var navScrollSpy = $(".navbar-scrollspy"),
$body = $('body'),
getNav = $('nav.navbar.bootsnav'),
offset = getNav.outerHeight();

if (navScrollSpy.length) {
$body.scrollspy({ target: '.navbar', offset: offset });

// Animation Scrollspy
$('.scroll').on('click', function (event) {
event.preventDefault();

// Active link
$('.scroll').removeClass("active");
$(this).addClass("active");

// Remove navbar collapse
$(".navbar-collapse").removeClass("in");

// Toggle Bars
$(".navbar-toggle").each(function () {
$(".fa", this).removeClass("fa-times");
$(".fa", this).addClass("fa-bars");
});

// Scroll
var scrollTop = $(window).scrollTop(),
$anchor = $(this).find('a'),
$section = $($anchor.attr('href')).offset().top,
$window = $(window).width(),
$minusDesktop = getNav.data("minus-value-desktop"),
$minusMobile = getNav.data("minus-value-mobile"),
$speed = getNav.data("speed");

if ($window > 992) {
var $position = $section - $minusDesktop;
} else {
var $position = $section - $minusMobile;
}

$('html, body').stop().animate({
scrollTop: $position
}, $speed);
});

// Activate Navigation
var fixSpy = function () {
var data = $body.data('bs.scrollspy');
if (data) {
offset = getNav.outerHeight();
data.options.offset = offset;
$body.data('bs.scrollspy', data);
$body.scrollspy('refresh');
}
}

// Activate Navigation on resize
var resizeTimer;
$(window).on('resize', function () {
clearTimeout(resizeTimer);
var resizeTimer = setTimeout(fixSpy, 200);
});
}
}
};

// Initialize
$(document).ready(function () {
bootsnav.initialize();
});

// Reset on resize
$(window).on("resize", function () {
bootsnav.hoverDropdown();
setTimeout(function () {
bootsnav.navbarSticky();
}, 500);

// Toggle Bars
$(".navbar-toggle").each(function () {
$(".fa", this).removeClass("fa-times");
$(".fa", this).addClass("fa-bars");
$(this).removeClass("fixed");
});
$(".navbar-collapse").removeClass("in");
$(".navbar-collapse").removeClass("on");
$(".navbar-collapse").removeClass("bounceIn");
});

$(window).scroll(function () {
var scroll = $(window).scrollTop();
if (scroll < 30) {
$('nav.navbar.bootsnav').removeClass('topbarbg');
//  $('nav.navbar.bootsnav').removeClass('no-background');
} else {
$('nav.navbar.bootsnav').addClass('topbarbg ');
}
});

});


}

render() {
return (
<div>
<div id="menu" className="d-block d-lg-none d-md-none">




<label htmlFor="tm" id="toggle-menu">
<div className="fleft">
<a href="/"><h1 class="colate-logo logo logo logo-scrolled">colate</h1> </a></div>   <div className="fright">
<div className="dashmenu"></div>
<div className="dashmenu"></div>
<div className="dashmenu"></div>
</div></label>







<input type="checkbox" id="tm" />
<ul className="main-menu clearfix">
<li>
<a href="#">
Company
<span className="drop-icon">▾</span>
<label title="Toggle Drop-down" className="drop-icon" htmlFor="sm1">
▾
</label>
</a>
<input type="checkbox" id="sm1" />
<ul className="sub-menu">
<li>
<Link to="/Vision">Vision</Link>
</li>

<li>
<Link to="/Mission">Mission</Link>
</li>
</ul>
</li>

<li>
<Link to="/">AI</Link>
</li>
<li>
<a href="#">
Solutions
<span className="drop-icon">▾</span>
<label title="Toggle Drop-down" className="drop-icon" htmlFor="sm2">
▾
</label>
</a>
<input type="checkbox" id="sm2" />
<ul className="sub-menu">
<li>
<a href="#">
Services
<span className="drop-icon">▾</span>
<label title="Toggle Drop-down" className="drop-icon" htmlFor="sm4">
▾
</label>
</a>
<input type="checkbox" id="sm4" />
<ul className="sub-menu">
<li>
<Link to="/CloudInfrastructure">
Cloud Infrastructure Automation
</Link>
</li>
<li>        <Link to="/DevopsAutomation">DevOps Automation</Link></li>
<li>
<Link to="/DevopsMetrics">Engineering Efficiency Measurement</Link>
</li>
<li>      <Link to="/ApplicationDevlopment">Application Development </Link></li>


</ul>
</li>
<li>
<a href="#">
<Link to="/Industries">Industries</Link>
<span className="drop-icon">▾</span>
<label title="Toggle Drop-down" className="drop-icon" htmlFor="sm5">
▾
</label>
</a>
<input type="checkbox" id="sm5" />
<ul className="sub-menu">
<li>
<li>
<Link to="/Fintech">Fintech</Link>
</li>
</li>
<li>
<Link to="/Telecommunication">Telecommunication</Link>
</li>
<li>
<Link to="/Healthcare">Healthcare</Link>
</li>
<li><Link to="/HardwareEngineering">Hardware Engineering</Link></li>
</ul>
</li>
<li className="dropdown">
<a
href="#"
className="dropdown-toggle active"
data-toggle="dropdown"
>
Products  <FontAwesomeIcon icon={faCaretRight} />
</a>
<ul className="dropdown-menu animated">
<li>
<Link to="/Cocreate">coCREATE</Link>
</li>

</ul>
</li>
</ul>
</li>
<li>
<a href="#">
Resources
<span className="drop-icon">▾</span>
<label title="Toggle Drop-down" className="drop-icon" htmlFor="sm6">
▾
</label>
</a>
<input type="checkbox" id="sm6" />
<ul className="sub-menu">
<li>
<a href="#">
<Link to="/EmergingTechnologies">Emerging Technologies</Link>
<span className="drop-icon">▾</span>
<label title="Toggle Drop-down" className="drop-icon" htmlFor="sm3">
▾
</label>
</a>
<input type="checkbox" id="sm3" />
<ul className="sub-menu">
<li>
<Link to="/Web3">Web 3.0 </Link>
</li>
<li>
<Link to="/Blockchain">Blockchain</Link>
</li>
<li>
<Link to="/Xrvr">XR (AR/VR)</Link>
</li>
</ul>
</li>
<li>
<li>
<Link to="/blogs">Blogs</Link>
</li>
<li>
<Link to="/WhitepaperListing">Whitepaper</Link>
</li>
</li>
</ul>
</li>

<li>
<Link to="/Contact">contact us</Link>
</li>
</ul>
</div>

<nav className="navbar d-none d-lg-block navbar-default navbar-fixed white1 no-background1 bootsnav">
{/* Start Top Search */}
<div className="top-search">
<div className="container">
<div className="input-group">
<span className="input-group-addon"><i className="fa fa-search"></i></span>
<input type="text" className="form-control" placeholder="Search" />
<span className="input-group-addon close-search"><i className="fa fa-times"></i></span>
</div>
</div>
</div>
{/* End Top Search */}
<div className="container-full">
{/* Start Header Navigation */}
<div className="navbar-header">
<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
<i className="fa fa-bars"></i>
</button>
<Link to="/" className="navbar-brand" >

<h1 class="colate-logo logo logo-display">colate</h1>
<h1 class="colate-logo logo logo logo-scrolled">colate</h1>
</Link>

</div>
{/* End Header Navigation */}
<div className="collapse navbar-collapse" id="navbar-menu">
<ul className="nav navbar-nav navbar-right  navbarright104" data-in="fadeInDown1" data-out="fadeOutUp">
<li className="dropdown">
<a href="#" className="dropdown-toggle active" data-toggle="dropdown">Company <FontAwesomeIcon icon={faCaretDown} /></a>
<ul className="dropdown-menu animated fadeOutUp1" style={{ display: 'none', opacity: 1 }}>
<li><Link to="/Vision">Vision</Link></li>
<li><Link to="/Mission">Mission</Link></li>
</ul>
</li>
<li><Link to="/">AI</Link></li>
<li className="dropdown">
<a
href="#"
className="dropdown-toggle active"
data-toggle="dropdown"
>
Products  <FontAwesomeIcon icon={faCaretDown} />
</a>
<ul className="dropdown-menu animated">
<li>
<Link to="/Cocreate">Cocreate</Link>
</li>

</ul>
</li>
<li className="dropdown">
<a href="#" className="dropdown-toggle active" data-toggle="dropdown">Solutions <FontAwesomeIcon icon={faCaretDown} /></a>
<ul className="dropdown-menu animated fadeOutUp1" style={{ display: 'none', opacity: 1 }}>
<li className="dropdown">
<a href="/Services" className="dropdown-toggle active" data-toggle="dropdown">Services <FontAwesomeIcon icon={faCaretRight} /></a>
<ul className="dropdown-menu animated">
<li><Link to="/CloudInfrastructure">Cloud Infrastructure Automation</Link></li>
<li><Link to="/DevopsAutomation">DevOps Automation</Link></li>
<li><Link to="/DevopsMetrics">Engineering Efficiency Measurement</Link></li>
<li><Link to="/ApplicationDevlopment">Application Development</Link> </li>

</ul>
</li>
<li className="dropdown">
<a href="/Industries" className="dropdown-toggle active" data-toggle="dropdown">Industries <FontAwesomeIcon icon={faCaretRight} /></a>
<ul className="dropdown-menu animated">
<li><Link to="/Fintech">Fintech</Link></li>
<li><Link to="/Telecommunication">Telecommunication</Link></li>
<li><Link to="/Healthcare">Healthcare</Link></li>
<li><Link to="/HardwareEngineering">Hardware Engineering</Link></li>
</ul>
</li>

</ul>
</li>
<li className="dropdown"><Link to="/">Resources <FontAwesomeIcon icon={faCaretRight} /></Link>
<ul className="dropdown-menu animated">
<li className="dropdown">
<a href="/EmergingTechnologies" className="dropdown-toggle active" data-toggle="dropdown">Emerging Technologies <FontAwesomeIcon icon={faCaretRight} /></a>
<ul className="dropdown-menu animated">
<li><Link to="/Web3">Web 3.0</Link></li>
<li><Link to="/Blockchain">Blockchain</Link></li>
<li><Link to="/Xrvr">XR (AR/VR)</Link></li>
</ul>
</li>
<li><Link to="/blogs">Blogs</Link></li>
<li><Link to="/WhitepaperListing">Whitepaper</Link></li>
</ul>
</li>
<li><Link to="/Contact">contact us</Link></li>


</ul>
</div>
</div>
</nav>
</div>
);
}

};