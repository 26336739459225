import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const MatrixSlider = () => {
  return (
    <OwlCarousel
      className="owl-theme"
      items={3}
      loop
      autoWidth = {false}
       mergeFit = {true}
      autoplay
      responsive={{
        0: {
          items: 1
        },
        1000: {
          items: 3
        },
        1200: {
          items: 3,
       
        }
      }}
    >
          <div className="item">
        <ul className="webapp mt-5">
        <li className="card webcard wow bounceInRight">
                          <div className="title mb-2 text-black-40 text-left"> Metrics That Matters </div>
                          <div className="content">
                            <p>Measure lead time, deployment frequency, change failure rate, and mean time to restore (MTTR) to gain unparalleled insights into your DevOps performance.</p>
                          </div>
                        </li>
                      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">

      <li className="card wow bounceInDown">
                          <div className="title">Data-Driven Decisions</div>
                          <div className="content">
                            <p>Embrace a data-driven approach to engineering decisions. DevOps Metrics Hub offers real-time analytics and visualizations, enabling you to make informed choices based on accurate performance data.</p>
                          </div>
                        </li>
      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">
      <li className="card wow bounceInRight">
                          <div className="title">Continuous Improvement</div>
                          <div className="content">
                            <p>With DORA metrics, continuous improvement becomes a reality. Identify bottlenecks and areas of improvement in your DevOps pipeline to optimize efficiency and deliver higher-quality software, faster.</p>
                          </div>
                        </li>
                      
                      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">                             <li className="card webcard wow bounceInDown">
                          <div className="title">Benchmarking and Best Practices</div>
                          <div className="content">
                            <p>Benchmark your DevOps performance against industry standards and best practices. DevOps Metrics Hub empowers you to compare your metrics with top-performing organizations and gain a competitive edge.</p>
                          </div>
                        </li>
                      
                      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">
      <li className="card webcard wow bounceInDown">
                            <div className="title">Seamless Integration</div>
                            <div className="content">
                              <p>Integrate DevOps Metrics Hub with your existing tools and workflows effortlessly. Our platform seamlessly integrates with popular CI/CD pipelines and collaboration platforms to streamline metric collection and reporting.</p>
                            </div>
                          </li>
                      
                      
                      </ul>
      </div>


  <div className="item">
  
    
  </div>
    </OwlCarousel>
  );
};

export default MatrixSlider;