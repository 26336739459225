import React from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';

const TabList = () => {
  return (
    <Tab.Container defaultActiveKey="tab1">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="tab1">Smart Homes</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab2">Education</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab3">Agriculture</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab4">Healthcare</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab5">Industrial IoT (IIoT)</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab6">Hospitality</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab7">Retail</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab8">Smart Cities</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab9">Transportation</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab10">Environmental Monitoring</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="tab1">
              <div>Wi-Fi is commonly used in smart home IoT devices such as smart thermostats, security cameras, smart lighting, and voice-controlled assistants. These devices require high-speed connectivity and local network access.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab2">
              <div>Wi-Fi is essential in educational institutions for connecting IoT devices such as tablets, interactive whiteboards, and classroom management systems.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab3">
              <div>Wi-Fi is used in smart agriculture for real-time monitoring of crop conditions, irrigation systems, and livestock.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab4">
              <div>Wi-Fi-enabled medical devices, like patient monitors and infusion pumps, transmit patient data to healthcare professionals for real-time monitoring and analysis.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab5">
              <div>In industrial settings, Wi-Fi is used for machine-to-machine communication, monitoring equipment status, and ensuring efficient production processes.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab6">
              <div>Hotels and resorts offer Wi-Fi connectivity for IoT devices like guestroom controls, access cards, and security systems.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab7">
              <div>Retailers use Wi-Fi for inventory management, point-of-sale systems, and customer analytics. Wi-Fi enables the collection of customer data and personalized shopping experiences.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab8">
              <div>Wi-Fi can be deployed in smart city applications, such as public Wi-Fi hotspots, smart streetlights, and traffic management systems.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab9">
              <div>Public transportation systems may use Wi-Fi for passenger information, ticketing, and real-time tracking of vehicles.</div>
            </Tab.Pane>
            <Tab.Pane eventKey="tab10">
              <div>Wi-Fi networks can support IoT sensors for environmental data collection, such as weather stations and air quality monitors.</div>
            </Tab.Pane>
           
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default TabList;
