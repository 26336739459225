import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const AddDevSlider = () => {
  return (
    <OwlCarousel
      className="owl-theme"
   
      responsive={{
        0: {
          items: 1
        },
        1000: {
          items: 3,
        },
        1200: {
          items: 3,
       
        }
      }}
    >
          <div className="item">
        <ul className="webapp mt-5">
        <li className="card webcard wow bounceInRight">
                  <div className="icon">
                    <i className="icon-screen-smartphone mb-1 d-block" />
                  </div>
                  <div className="title mb-2 text-black-40 text-left">Pioneering Technology & Best Practices</div>
                  <div className="content">
                   Experience cutting-edge solutions built on industry best practices. We advocate for Test-Driven Development (TDD), ensuring robust, error-free applications. Our developers embrace a "Quality First" ethos, guaranteeing flawless functionality and performance.
                  </div>
                </li>
                      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">
      <li className="card wow bounceInDown">
                  <div className="icon">
                    <i className="icon-bulb mb-1 d-block" />
                  </div>
                  <div className="title">100% Automation, Zero Compromises</div>
                  <div className="content">
                    At Colate, we write solutions with 100% automation, eliminating manual errors and streamlining the development process. Our commitment to automation translates into efficient, reliable, and future-ready applications.
                  </div>
                </li>
      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">
      <li className="card wow bounceInRight">
                  <div className="icon">
                    <i className="icon-globe mb-1 d-block" />
                  </div>
                  <div className="title">Cross-Platform Applications</div>
                  <div className="content">
                    Need an app that works on multiple platforms without compromising
                    performance? Our cross-platform development expertise allows us to
                    create apps that can be deployed across various operating systems
                    without sacrificing quality.
                  </div>
                </li>
                      
                      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">                              <li className="card webcard wow bounceInDown">
                  <div className="icon">
                    <i className="icon-key mb-1 d-block" />
                  </div>
                  <div className="title"> Tailored Solutions, Maximum Impact</div>
                  <div className="content">
                    {" "}
                    Every business is unique, and so are our solutions. We follow a tailored approach, aligning our development strategies with your specific needs. Colate's team of experts ensures that your application mirrors your vision while maximizing its impact.
                  </div>
                </li>
                      
                      </ul>
      </div>
      <div className="item">
      <ul className="webapp mt-5">
      <li className="card wow bounceInRight">
                  <div className="icon">
                    <i className="icon-bulb mb-1 d-block" />
                  </div>
                  <div className="title">Solutions for All Sizes</div>
                  <div className="content">
                    At Colate, we firmly believe that the latest technology should not be restricted to large companies. We cater to businesses of all sizes, empowering startups, SMEs, and enterprises alike with cutting-edge solutions that fuel growth and innovation.
                  </div>
                </li>
                      
                      </ul>
      </div>


  <div className="item">
  
    
  </div>
    </OwlCarousel>
  );
};

export default AddDevSlider;