export const seoData = {
    "vision" : {
        "title" : "Vision",
        "description": "Join Colate in shaping a sustainable and inclusive digital future. Our mission is to pioneer sustainable technology solutions, break accessibility barriers, and empower businesses of all sizes to thrive. Explore how we're making a difference today."
    },
    "mission" : {
        "title" : "Mission",
        "description": "At Colate, we empower businesses with innovative technology solutions. Our services include exceptional web and mobile development, seamless cloud infrastructure automation, and efficient DevOps solutions. Discover how we can elevate your business today"
    },
    "xrvr" : {
        "title" : "XR-VR Environment That Replaces the Real World",
        "description": "Discover XR, the umbrella term for Virtual Reality (VR), Augmented Reality (AR), and Mixed Reality (MR). XR technologies blend physical and digital realms, offering users immersive, interactive experiences. Learn more about the future of extended reality."
    },
    "services" : {
        "title" : "Services",
        "description": "Colate provides a comprehensive suite of tailored services to meet your business needs. Our expert team is dedicated to delivering top-notch solutions, ensuring efficiency and effectiveness in achieving your goals. Explore our range of services designed to elevate your business."
    },
    "cloudInfrastructure" : {
        "title" : "Cloud Infrastructure Simplified",
        "description": "Elevate your DevOps and streamline cloud infrastructure with Colate's Infrastructure Automation solutions. Embrace innovation while optimizing costs and maximizing productivity. Discover how our solutions can propel your business forward."
    },
    "DevopsAutomation" : {
        "title" : "DevOps automation | Unlock Seamless DevOps Excellence with Colate",
        "description": "Experience the future of DevOps automation with Colate, where challenges become opportunities and innovation thrives without limits. Discover how we redefine software development with cutting-edge automation solutions tailored to your unique challenges."
    },
    "DevopsMetrics" : {
        "title" : "Drive Your DevOps Success with Metrics that Matter",
        "description": "Striving for engineering excellence in your DevOps journey? Discover the DevOps Metrics Hub – your one-stop destination for adopting and leveraging DORA metrics to elevate software delivery performance. Measure and improve your engineering practices effectively."
    },
    "ApplicationDevlopment" : {
        "title" : "Application Devlopment | Exceptional Application Development by Colate",
        "description": "Embark on a transformative journey with Colate, where application development is redefined. Our innovative approach blends expertise, crafting bespoke digital solutions that resonate with your audience and deliver exceptional user experiences. Explore how we redefine the digital landscape."
    },
    "Industries" : {
        "title" : "Industries | Focus n continuous learning and adaptation",
        "description": "Discover our startup's strategy focused on innovation, agility, and market disruption. We differentiate ourselves by challenging the status quo and embracing emerging technologies to deliver unique products and services. Stay ahead of industry trends and customer needs with us, and proactively identify growth opportunities."
    },
    "Fintech" : {
        "title" : "Fintech | Welcome to our fintech product and services offering",
        "description": "At Colate, we're dedicated to revolutionizing the finance world with innovative solutions and cutting-edge technology. We believe in providing secure, reliable financial services for everyone to achieve their goals and dreams. Discover our state-of-the-art products empowering individuals and organizations toward financial freedom."
    },
    "Telecommunication" : {
        "title" : "Telecommunication Services",
        "description": "Explore our telecommunications group, committed to connecting the world with innovative solutions and state-of-the-art technology. Discover how we're revolutionizing connectivity for a seamless global experience."
    },
    "Healthcare" : {
        "title" : "Innovative healthcare solutions",
        "description": "Welcome to our healthcare section, where we're dedicated to enhancing lives through innovative healthcare solutions and compassionate care. Discover how we're committed to advancing healthcare for better outcomes and improved well-being."
    },
    "HardwareEngineering" : {
        "title" : "Hardware Engineering",
        "description": "At Colate, we lead the way in hardware engineering innovation. Our skilled and passionate team delivers cutting-edge solutions tailored to your needs, whether you're a startup launching a new product or an established company enhancing hardware capabilities. Let us guide you through every step of the process."
    },
    "Cocreate" : {
        "title" : "Cocreate",
        "description": "Discover AI-powered automation products for DevOps, websites, cloud engineering, product development, and testing. Streamline your processes and enhance efficiency with our innovative solutions."
    },
    "EmergingTechnologies" : {
        "title" : "Emerging technologies",
        "description": "Explore emerging technologies reshaping industries and society, from artificial intelligence to quantum computing and biotechnology. Discover how these innovations are solving complex challenges and driving the future of technological progress."
    },
    "Web3" : {
        "title" : "Shift towards Web 3.0.",
        "description": "Experience the massive shift towards Web 3.0 with our dedicated team investing, focusing, and partnering with multiple companies to develop innovative applications. Join us in shaping the future of the decentralized web."
    },
    "Blockchain" : {
        "title" : "Blockchain technology transformative innovation",
        "description": "Explore the transformative innovation of blockchain technology, revolutionizing data and value storage, verification, and exchange. Discover its decentralized, immutable, and transparent ledger, enabling secure and trustless transactions and interactions."
    },
    "Xrvr" : {
        "title" : "XR-VR Environment That Replaces the Real World",
        "description": "Discover XR (Extended Reality) – the umbrella term for Virtual Reality (VR), Augmented Reality (AR), and Mixed Reality (MR). Explore how XR technologies merge physical and digital realms, delivering immersive and interactive experiences for users."
    },
    "whitepaperlisting" : {
        "title" : "Whitepaper | Colate",
        "description": "Experience the pivotal transformation in software development with the convergence of Artificial Intelligence (AI), Continuous Integration and Continuous Delivery (CI/CD), and Infrastructure. Explore how technology's rapid evolution shapes the future of software development."
    },
    "whitepaper" : {
        "title" : "Whitepaper | Colate’s role inharnessing AI in CI/CD for Optimized Infrastructure",
        "description": "Experience the pivotal transformation in software development with the convergence of Artificial Intelligence (AI), Continuous Integration and Continuous Delivery (CI/CD), and Infrastructure. Explore how technology's rapid evolution shapes the future of software development."
    },
    "SoftwareDevelopment" : {
        "title" : "A Paradigm Shift in Software Development",
        "description": "Discover the paradigm shift in software development through integrating AI with CI/CD. Explore how this integration revolutionizes workflows, enhances efficiency, and drives innovation in the development process."
    },
    
    "Contact" : {
        "title" : "Colate | Send us your query",
        "description": "Submit your query, and our dedicated team will respond promptly! Get the assistance you need with our responsive support services."
    },
}