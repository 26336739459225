import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import img240 from './../../../assets/img/gallery/e-sim.jpg';
import img250 from './../../../assets/img/gallery/iot-data-plan.jpg';
import img260 from './../../../assets/img/gallery/cellular-iot.jpg';
import img270 from './../../../assets/img/gallery/private-lte.jpg';
import img280 from './../../../assets/img/gallery/iot-device-mangement.jpg';
const TabsContent = () => {
    return (
        <div className='row'>
        <div className='col-lg-12 offset-lg-0 col-sm-12 col-xs-12 mt-5 mb-5 ibpm-nav'>
      <Tabs defaultActiveKey="tab1" id="my-tabs">
        <Tab eventKey="tab1" title="eSIM (Embedded SIM)">
          {/* Content for Tab 1 */}
          <div className="row">
              <div className="col-lg-5 offset-lg-0 mt-5">
                <img src={img240}  />
              </div>
              <div className="col-lg-5 offset-lg-1 hero_content  mt-5">     <p className="mb-2 text-black-40 text-left">
                      eSIM technology allows IoT devices to switch between different cellular networks without the need to replace physical SIM cards. It simplifies device management and roaming for global IoT deployments.</p>
              </div>

            </div>
        </Tab>
        <Tab eventKey="tab2" title="Private LTE/5G Networks">
          {/* Content for Tab 2 */}
          <div className="row">
            
              <div className="col-lg-5 offset-lg-0 hero_content  mt-5">    <p className="mb-2 text-black-40 text-left"> In some cases, enterprises and organizations deploy private LTE or 5G networks to provide dedicated and secure connectivity for their IoT devices. These networks are particularly valuable in industrial and campus settings.</p>
              </div>
              <div className="col-lg-5 offset-lg-1 mt-5">
                <img src={img270}  />
              </div>
            </div>
        </Tab>
        <Tab eventKey="tab3" title="IoT Device Management Platforms">
          {/* Content for Tab 3 */}
          <div className="row">
              <div className="col-lg-5 offset-lg-0 mt-5">
                <img src={img280}  />
              </div>
              <div className="col-lg-5 offset-lg-1 hero_content  mt-5">        <p className="mb-2 text-black-40 text-left"> Cellular carriers offer IoT-specific data plans that cater to the data usage patterns and requirements of IoT devices. These plans may include options for low data rate, low-cost connectivity for massive IoT deployments.</p>
              </div>

            </div>
      
        </Tab>

        <Tab eventKey="tab4" title="Cellular IoT Modules">
          {/* Content for Tab 3 */}
          <div className="row">
            
              <div className="col-lg-5 offset-lg-0 hero_content  mt-5">          <p className="mb-2 text-black-40 text-left">IoT modules with embedded cellular connectivity are available from various manufacturers. These modules integrate cellular modems, antennas, and SIM card slots into a compact form factor, making it easier to add cellular connectivity to IoT devices.</p>
              </div>
              <div className="col-lg-5 offset-lg-1 mt-5">
                <img src={img260}  />
              </div>
            </div>
        
        </Tab>

        <Tab eventKey="tab5" title="IoT Data Plans">
          {/* Content for Tab 3 */}
          <div className="row">
              <div className="col-lg-5 offset-lg-0 mt-5">
                <img src={img250}  />
              </div>
              <div className="col-lg-5 offset-lg-1 hero_content  mt-5">             <p className="mb-2 text-black-40 text-left"> Cellular carriers offer IoT-specific data plans that cater to the data usage patterns and requirements of IoT devices. These plans may include options for low data rate, low-cost connectivity for massive IoT deployments.</p>
              </div>
            </div>
        
        </Tab>
      </Tabs>
      </div>
      </div>


    );
  };
  export default TabsContent;