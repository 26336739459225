import React, { useEffect, useRef } from "react";

function Editor({ onChange, editorLoaded, name, value }:any) {
  const editorRef:any = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic")
    };
  }, []);

  return (
    <div>
      {editorLoaded ? (
        <>
        <label>Description</label>
        <CKEditor
          type=""
          style={{width:'600px'}}
          name={name}
          editor={ClassicEditor}
          toolbar = {{
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                '|', 'link', 'blockQuote', 'codeBlock',
                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ],
            shouldNotGroupWhenFull: false
        }
        }
          data={value}
          onChange={(event:any, editor:any) => {
            const data = editor.getData();
            // console.log({ event, editor, data })
            onChange(data);
          }}
        />
        </>
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default Editor;
