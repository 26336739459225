import { useEffect } from 'react';
import WOW from 'wowjs';
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import matrics1 from './../../../assets/img/devops-metrics-hub-lead.jpg';
import abt from './../../../assets//img/about.svg';
import img190 from './../../../assets/img/gallery/ai-img1.png';
import img200 from './../../../assets/img/devops-reality-5.png';
import img210 from './../../../assets/img/devops_99.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import BoxSlider from './BoxSlider';
import AddDevSlider from './AddDevSlider';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function DevopsMatrics() {
  const divStyle = {
          backgroundImage: `url(${img440})`
      }

  const Metrics = {
    backgroundImage: `url(${matrics1})`
  }
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
      <Seo title={seoData.DevopsAutomation.title} description={seoData.DevopsAutomation.description}/>
      <Npp />
      <section className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="row align-items-center1">
            <div className="col-lg-5 col-md-5 wow bounceInRight">
              <div className="about-caption mb-50">
                <div className="section-tittle mb-25 content ">
                <h2 className='mb-2 text-black-40 text-left'>
                    Unlock Seamless DevOps Excellence with Colate
                  </h2>
                  <p className="text-black-40 mt-5">
                    Welcome to the future of DevOps automation – where challenges turn into opportunities, and innovation knows no bounds. At Colate, we redefine software development through cutting-edge automation solutions tailored to your unique challenges.
                  </p>
                  <p className="text-black-40 mt-3">
                  Our end-to-end CI/CD solutions powered by GitLab and ArgoCD empower businesses to achieve seamless, secure, and efficient software delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 offset-lg-1 col-xs-12 wow bounceInLeft">
              <div className="about-img mt502">
                <img src={img190} alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area2 pb-bottom section-padding40 wow bounceInRight">
        <div className="container">
          <div className="row align-items-center">
            <div className="about-caption mb-50">
                <div className="section-tittle mb-25 content  hero_content">
                  <h3 className='mb-2 text-black-40 text-left'>
                    Elevate Your DevOps Journey with Colate: Your Trusted DevOps Partner
                  </h3>
                  <p className="text-black-40 mt-3 ">
                    Experience a transformational DevOps journey where innovation meets efficiency, and challenges transform into opportunities.
                  </p>
                  <p><b>Why Choose Colate's CI/CD Solutions?</b></p>
             
                </div>
              </div>
          </div>
        </div>
      </section>
      <div className="case-studies-area default-padding-bottom">

<AddDevSlider />
</div>

      <section className="wantToWork-area default-padding ">
          <div className="container">
            <div
              className="wants-wrapper w-padding2 section-bg2"
              style={divStyle}
            >
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-7 col-lg-9 col-md-8">
                  <div className="wantToWork-caption text-center">
                    <h2>Ready to Experience Effortless DevOps Excellence?</h2>
                    <p>
                      Experience DevOps automation reimagined – where every solution is as unique as your venture. Discover the future of software development with Colate today.
                    </p>
                    <a href="/Contact" className="btn3 wantToWork-btn">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
      </section>

  
      <Footer />
    </>
  )
}

export default DevopsMatrics