import { useEffect } from 'react';
import WOW from 'wowjs';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import misBgimg from './../../../assets/img/mission-bg-plan.jpeg';
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/stratagy_left.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import './../../../pages/UI/common/digital.css';

function CommunicationMedia() {
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  const divStyle = {
      backgroundImage: `url(${img440})`
  }

  const misBg = {
    backgroundImage: `url(${misBgimg})`
  }
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>

      <section className="about-area2 section-padding40w pt176 section-bg2 comm">
        <div className="container-fluid centertext-top">
          <div className="row 1align-items-center">
     <div className='col-lg-6 col-sm-6 col-md-4 col-xs-12 offset-lg-1'>
          <div className='realestateblock2'>
          {/* <h1 className='blackcolor wow bounceInUp'>Embracing Innovation <span className='block'></span>
            Fintech and Banking Services in the Digital Era</h1> */}

<h1 className='whitecolor wow bounceInUp'>Communication & Media</h1>

          </div>
          </div>
          </div>
        </div>
      </section>


  
    </>
  )
}

export default CommunicationMedia