import { useEffect } from 'react'
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import automation1 from './../../../assets/img/automation-lead.jpg';
import automation2 from './../../../assets/img/automation-content-v2.jpg';
import automation3 from './../../../assets/img/ci-cd-automation.jpg';
import automation4 from './../../../assets/img/devops.jpg';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Automation() {
  const automationbg = {
    backgroundImage: `url(${automation1})`
  }
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
     <Seo title={seoData.vision.title} description={seoData.vision.description}/>
      <Npp />
      <div
        className="breadcrumb-area bg-theme-blue1  bg-gray1 text-center shadow1 gradient-bg dark1 text-light bg-cover"
        style={automationbg}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Automation</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="index.html">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Automation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="services-content-area text-center1 carousel-shadow default-padding">
        <div className="container-full">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
            </div>
          </div>
          <div className="blog-area full-blog right-sidebar full-blog default-padding">
            <div className="container">
              <div className="blog-items mt-5">
                <div className="row">
                  <div className="blog-content col-md-9 offset-md-1 col-lg-9">
                    <div className="blog-item-box">
                      {/* Single Item */}
                      <div className="single-item">
                        <div className="item">
                          <h3>
                            <a href="#">
                              — Streamline your infrastructure and elevate your DevOps
                              with automation —
                            </a>
                          </h3>
                          <div className="thumb">
                            <img
                              src={automation2}
                              alt="Thumb"
                            />
                            <div className="date">Automation content V2</div>
                            <div className="info">
                              <p>
                                <strong> Infrastructure automation:</strong>
                              </p>
                              <p>
                                As we enter the next era of digital advancements, it's
                                imperative to have a strong cloud strategy that
                                enables you to harness the power of the cloud while
                                keeping costs low and productivity high.
                              </p>
                              <p>
                                At Colate.io, we have built a platform to make cloud
                                computing more accessible and secure for businesses of
                                all sizes where you can manage your cloud resources,
                                automate workloads, and optimize your cloud
                                infrastructure to achieve better performance and
                                cost-efficiency without having deep knowledge on the
                                cloud architecture, you can focus on your core
                                business functions, reduce costs, and improve
                                collaboration across teams.
                              </p>
                              <a
                                className="btn circle btn-theme border btn-sm"
                                href="#"
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* End Single Item */}
                        {/* Single Item */}
                        <div className="single-item">
                          <div className="item">
                            <h3>
                              <a href="#">
                                – Revolutionize Your Continuous Integration and
                                Continuous Delivery with Colate –{" "}
                              </a>
                            </h3>
                            <div className="thumb">
                              <img
                                src={automation3}
                                alt="Thumb"
                              />
                              <div className="date">CI/CD Automation</div>
                            </div>
                            <div className="info">
                              <p>
                                At Colate, we have a next-generation platform for
                                continuous delivery that is changing the way
                                developers and DevOps teams work. Our platform
                                provides a centralized solution for building, testing
                                and deploying applications, all while improving the
                                speed and reliability of your development cycle.
                              </p>
                              <a
                                className="btn circle btn-theme border btn-sm"
                                href="#"
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="single-item">
                          <div className="item">
                            <h3>
                              <a href="#">Powerful DevOps platform</a>
                            </h3>
                            <div className="thumb">
                              <img src={automation4} alt="Thumb" />
                              <div className="date">DevOps Workflow Automation</div>
                            </div>
                            <div className="info">
                              <p>
                                At Colate, we have a powerful DevOps platform that
                                helps you streamline your workflows, increase
                                efficiency and reduce cycle times. Our platform is
                                designed to help you identify problems in your
                                pipeline before they become a problem, so you can stay
                                ahead of the curve.
                              </p>
                              <a
                                className="btn circle btn-theme border btn-sm"
                                href="#"
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-area default-padding bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-md-9 offset-md-1 col-sm-12 col-xs-12 mt-5">
                <p>
                  Welcome to our CICD expertise, we blend right amount of automation
                  for excellent software lice cycle, where we provide cutting-edge
                  Continuous Integration and Continuous Delivery (CI/CD) solutions to
                  help your organization achieve faster, more reliable software
                  delivery.
                </p>
                <p>
                  At our core, we believe that software development should be a
                  collaborative, iterative process that enables organizations to
                  quickly and efficiently deliver high-quality software to their
                  customers. Our CI/CD solutions are designed to automate the software
                  development process, reduce errors, and ensure that software is
                  delivered on time and on budget.
                </p>
                <p>
                  Our CI/CD solutions include a range of tools and services designed
                  to streamline software development and delivery, including version
                  control systems, automated testing frameworks, build and deployment
                  automation tools, and more. Whether you're a small startup or a
                  large enterprise, we have the expertise and technology to help you
                  achieve your software development goals.
                </p>
                <p>
                  But more than just a provider of CI/CD solutions, we are a company
                  that's deeply committed to our customers' success. We understand
                  that every organization is unique, and we work closely with our
                  customers to develop customized solutions that meet their specific
                  needs and help them achieve their software development goals.
                </p>
                <p>
                  As we continue to innovate and develop new CI/CD solutions, we
                  remain dedicated to our core values of quality, reliability, and
                  innovation. We are excited to be at the forefront of the software
                  development industry, and we look forward to helping you achieve
                  faster, more reliable software delivery.
                </p>
                <p>
                  We understand that software development can be a complex and
                  time-consuming process, which is why we are committed to providing
                  our customers with the tools and support they need to streamline
                  their software development process and deliver high-quality software
                  faster.
                </p>
                <p>
                  Our CI/CD solutions are designed to optimize every step of the
                  software development process, from code integration to testing,
                  deployment, and delivery. With our solutions, you can automate your
                  software development process, reduce manual errors, and achieve
                  greater visibility and control over your software delivery pipeline.
                </p>
                <p>
                  In addition to our software solutions, we also offer expert
                  consulting and training services to help you get the most out of
                  your CI/CD tools. Our team of experienced software development
                  professionals is dedicated to helping you optimize your software
                  development process and achieve your software development goals.
                </p>
                <p>
                  We also understand the importance of staying ahead of the curve in
                  an ever-evolving industry. That's why we are constantly investing in
                  research and development to stay at the forefront of the latest
                  CI/CD trends and technologies. From DevOps best practices to
                  containerization and micro-services architecture, we are committed
                  to developing the solutions of tomorrow to meet the needs of our
                  customers today. At our core, we believe that software development
                  should be an agile, iterative process that enables organizations to
                  deliver high-quality software faster and more efficiently. We are
                  proud to be a part of this important industry, and we look forward
                  to helping you achieve your software development goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fun-fact-area text-center text-light default-padding">
          <div className="container">
            <div className="inner-items">
              <div className="row">
                <div className="col-lg-3 col-md-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={687} data-speed={5000}>
                      687
                    </div>
                    <span className="medium">Projects Executed</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={2348} data-speed={5000}>
                      2348
                    </div>
                    <span className="medium">Data Analytics</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={450} data-speed={5000}>
                      450
                    </div>
                    <span className="medium">Data Management</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 item">
                  <div className="fun-fact">
                    <div className="timer" data-to={1200} data-speed={5000}>
                      1200
                    </div>
                    <span className="medium">Satisfied Customers</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Automation