import { useEffect } from 'react'
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import healthcare2 from './../../../assets/img/healthcare_left_1.png';
import img240 from './../../../assets/img/health_2.png';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Healthcare() {
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);
  return (
    <>
    <Seo title={seoData.Healthcare.title} description={seoData.Healthcare.description}/>
      <Npp />
      <div className="about-area2 section-padding40w pt176 section-bg2">
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-6 col-md-5 wow bounceInLeft">
                <div className="content">
                  {/* <h4 className="mb-2 text-black-40 text-left">Healthcare
                  </h4> */}
               
                  <h1 className="mb-2 text-black-40 text-left"> Innovative healthcare solutions</h1>
                  <p className="mb-2 text-black-40 text-left">
                    Welcome to our healthcare section, where we are dedicated to
                    improving lives through innovative healthcare solutions and
                    compassionate care.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    We believe that healthcare is a fundamental human right, and that
                    everyone deserves access to high-quality, affordable healthcare
                    services. That's why we are committed to developing cutting-edge
                    products and services that empower individuals and organizations
                    to achieve their health goals and live their best lives.
                  </p>
                  <p className="mb-2 text-black-40 text-left">
                    Whether you're looking for primary care services, specialized
                    treatments, or wellness programs, we have the expertise and
                    technology to meet your needs. From telemedicine and digital
                    health solutions to personalized medicine and precision
                    diagnostics, we offer a wide range of products and services to
                    help you achieve optimal health and wellbeing.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 offset-lg-1 col-xs-12 wow bounceInRight">
                <div className="thumb">
                  <img
                    src={healthcare2}
                    alt="Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area2 section-padding40w pt176 section-bg2 wow bounceInUp">
        <div className="container">
          <div className="inner-items">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-xs-12">
                <img
                  src={img240}
                  alt="Thumb"
                />
              </div>
              <div className="col-lg-6 col-md-5 offset-lg-2 mt-5">
                <div>
                  <h2 className="mb-2 text-black-40 text-left">
                    Healtcare is a fundamental human right
                  </h2>
                  <p className="mb-2 text-black-40 text-left">
                    But more than just a provider of healthcare solutions, we are a
                    company that's deeply committed to our patients and partners. We
                    believe that healthcare is a collaborative effort, and we work
                    closely with our patients, healthcare providers, and other
                    stakeholders to develop tailored solutions that meet their unique
                    needs and help them achieve their goals.
                  </p>
                  <p></p>
                  <p className="mb-2 text-black-40 text-left">
                    As we continue to innovate and develop new products and services,
                    we remain dedicated to our core values of quality, compassion, and
                    innovation. We are excited to be at the forefront of the
                    healthcare industry, and we look forward to helping you achieve
                    optimal health and wellbeing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-area default-padding2 bg-grayq wow bounceInUp">
        <div className="container">
          <div className="testimonial-items text-center1">
            <div className="row">
              <div className="col-lg-12 offset-lg-0">
                <div className="heading  mt-5 hero_content">
                  <p>
                    We understand that
                    healthcare can be complex and overwhelming, which is why we are
                    committed to providing our patients with exceptional care and
                    support. Our team of healthcare professionals is dedicated to
                    helping you navigate the healthcare system, understand your
                    options, and make informed decisions about your health.
                  </p>
                  <p className="mt-3">
                    In addition to our products and services, we also offer
                    educational resources and support to help you stay informed and
                    engaged in your health. From online health libraries and support
                    groups to personalized health coaching and disease management
                    programs, we are here to help you achieve your health goals and
                    live your best life.
                  </p>
                  <p className="mt-3">
                    We also understand the importance of staying ahead of the curve in
                    an ever-evolving industry. That's why we are constantly investing
                    in research and development to stay at the forefront of the latest
                    healthcare trends and technologies. From precision medicine and
                    genomics to artificial intelligence and blockchain, we are
                    committed to developing the solutions of tomorrow to meet the
                    needs of our patients today.
                  </p>
                  <p className="mt-3">
                    At our core, we believe that healthcare is about more than just
                    treating illness. It's about promoting health, preventing disease,
                    and improving the overall quality of life for individuals and
                    communities. We are proud to be a part of this important industry,
                    and we look forward to helping you achieve optimal health and
                    wellbeing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Healthcare