import { useEffect } from 'react'
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer'
import img150 from './../../../assets/img/devops-release-management-left.jpg';
import abt from './../../../assets//img/about.svg';
import img160 from './../../../assets/img/devops-reality-5.png';
import img170 from './../../../assets/img/devops-release-management-lead.gif';
import img180 from './../../../assets/img/key-features-release-management.jpg';

function DevopsRelease() {
  const devopsrelease = {
    backgroundImage: `url(${img170})`
  }
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>
      <Npp />
      <section className="about-area2 Wsection-bg2  xs-padding-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 col-md-12">
              <div className="about-caption mb-50">
                <div className="about-icon">
                  <img src={abt} alt="Thumb" />
                </div>
                <div className="section-tittle mb-25">
                  <h1 className="mb-2 text-black-40 text-left">WHAT IS DEVOPS RELEASE MANAGEMENT?</h1>
                </div>
                <p className="mb-20 mb-2 text-black-40 text-left">
                  At Colate, we have a next-generation platform for continuous
                  delivery that is changing the way developers and DevOps teams work.
                  Our platform provides a centralized solution for building, testing
                  and deploying applications, all while improving the speed and
                  reliability of your development cycle.
                </p>
              
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-9 col-sm-9">
              <div className="about-img">
                <img src={img150} alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area2 pb-bottom section-padding401">
        <div className="container">
          <div className="row align-items-center1">
            <div className="col-xl-6 col-lg-5 col-md-9">
              <div className="about-img clippath">
                <img src={img160} alt="Thumb" />

              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 ptmobile">
              <div className="about-caption mb-50">
                {/* <div className="about-icon service">
                  <img src={abt} alt="Thumb" />
                </div> */}
                <div className="section-tittle mb-25">
                  <h2 className="mb-2 text-black-40 text-left">WHY CHOOSE OUR SOLUTION FOR DEVOPS RELEASE MANAGEMENT?</h2>
                </div>
                <p>
                  <strong>1. Continuous and Reliable Deployments</strong>
                  <br />
                  DevOps Release Management Hub empowers you to achieve continuous
                  delivery by automating the entire release process. Say goodbye to
                  manual interventions and experience reliable deployments every time.
                </p>
                <p>
                  <strong>2. Reduced Deployment Risk</strong>
                  <br />
                  Our platform ensures that all releases go through rigorous testing
                  and quality checks, minimizing the risk of production incidents and
                  customer-impacting issues.
                </p>
                <p>
                  <strong>3. Efficient Change Management</strong>
                  <br />
                  Manage and track changes to your codebase and infrastructure with
                  ease. DevOps Release Management Hub enables you to monitor changes
                  in real-time, providing complete visibility and accountability.
                </p>
                <p>
                  <strong>4. Collaborative Workflows</strong>
                  <br />
                  Facilitate seamless collaboration between development, operations,
                  and testing teams. Our platform encourages cross-functional
                  communication, enabling teams to work together harmoniously.
                </p>
                <p>
                  <strong>5. Continuous Improvement</strong>
                  <br />
                  With comprehensive release analytics and insights, you can
                  continuously improve your release processes. Identify bottlenecks
                  and optimize your workflows for maximum efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="why-choose-us-area default-padding-bottom bg-cover text-light black-color1"
        style={devopsrelease}
      >
        <div className="container">
          <div className="inner-items">
            <div className="row">
              <div className="col-lg-7 offset-lg-5">
                <div className="info">
                  <h4>Streamline Your Release Management Today</h4>
                  <p>
                    Unlock the true potential of your software releases with DevOps
                    Release Management Hub. Say goodbye to manual and error-prone
                    deployments, and embrace a culture of continuous improvement.
                    Request a demo now and experience the efficiency and confidence
                    that come with streamlined release management.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area2 section-padding40w pt176 section-bg2 default-padding">
        <div className="container stickycontainer">
          <div className="bucket-sticky">
            <div className="testimonial-items text-center1">
              <div className="services-details-items">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-xs-12 services-single-content mt-5">
                    <a id="code" />
                    <div className="pb120">
                      <h4>Key Features of DevOps Release Management Hub</h4>
                      <p>
                        <strong>1. Automated Deployment Pipelines </strong>
                        <br />
                        GitOps leverages the version control capabilities of Git to
                        store infrastructure and application configuration. This
                        provides a historical record of changes and enables easy
                        auditing, troubleshooting, and rollback to previous states.
                      </p>
                      <p>
                        <strong>2. Version Control Integration </strong>
                        <br />
                        Integrate with your preferred version control systems (e.g.,
                        Git, SVN) to manage code changes and ensure that the right
                        versions are deployed to production.
                      </p>
                      <p>
                        <strong>3. Environment Management </strong>
                        <br /> Efficiently manage different environments, such as
                        development, staging, and production, ensuring consistency and
                        reproducibility across the release pipeline.
                      </p>
                      <p>
                        <strong>4. Release Orchestration</strong>
                        <br />
                        Orchestrate complex release workflows with ease. DevOps
                        Release Management Hub enables you to manage multiple services
                        and applications in a single, coordinated release.
                      </p>
                      <p>
                        <strong>5. Automated Testing and QA</strong>
                        <br /> Automate testing processes to validate your software at
                        every stage of the release pipeline. From unit tests to
                        integration and user acceptance testing, our platform ensures
                        high-quality releases.
                      </p>
                      <p>
                        <strong>6. Rollback and Roll Forward </strong>
                        <br /> Prepare for any eventuality with built-in rollback and
                        roll-forward capabilities. Easily revert to previous versions
                        or progress to newer releases in case of issues.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12 pb120">
                    <img src={img150} alt="Thumb" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9 offset-lg-1"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DevopsRelease