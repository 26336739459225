import { useEffect } from 'react';
import WOW from 'wowjs';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import cocreate1 from './../../../assets/img/gallery/cocrate1.jpg';
import cocreate2 from './../../../assets/img/gallery/all-in-one.jpg';
import cloudlogos from './../../../assets/img/gallery/cloud-logos.png';
import onprem from './../../../assets/img/gallery/onprem-logo.png';
import applicationlogo from './../../../assets/img/gallery/application-logos.png';
import launch from './../../../assets/img/gallery/launch.png';

import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/gallery/what-is-init.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function Cocreate() {
const divStyle = {
backgroundImage: `url(${img440})`
}
useEffect(() => {
  const wow = new WOW.WOW({live: false});
  wow.init({
    duration: 1000
  });
  
}, []);

useEffect(() => {
document.body.classList.remove('dark-layout');
}, []);

return (
<>
<Seo title={seoData.Cocreate.title} description={seoData.Cocreate.description}/>
<Npp/>
<section className="about-area2 section-padding40w pt176 section-bg2 cocreate pbdesktop">
<div className="container header-eight">
<div className="row align-items-center banner-center-margin">
<div className="col-lg-4 col-md-12 col-12 wow bounceInLeft">
<div className="header-content">
  <h1>Introducing coCREATE</h1>
  <p>
  An <span id='aitxt'>AI</span> powered intelligent automation platform for Application Development, DevSecOps, Cloud Engineering and more..
  </p>
    <div className="margin-right15w mt-5">
    {/* <a href="javascript:void(0)" className="primary-btn">Sign-in</a> */}

    
    <a href="https://co-create.colate.io" target="_blank" className="btn-animation border dark smooth-menu margin-right15" data-animation="animated slideInUp">
        Try Now
    </a>
  </div>



</div>
</div>
<div className="col-lg-7 offset-lg-1 col-md-12 col-12 mobmt50 wow bounceInRight">
<div className="header-image">
<img
          src={cocreate1}
          alt="Thumb"
        />
</div>
</div>
</div>
</div>
</section>

<section className="about-area about-five">
<div className="container">
<div className="row align-items-center">
<div className="col-lg-6 col-12 wow bounceInDown">
<div className="about-image-five">

<img
          src={cocreate2}
          alt="Thumb"
        />
</div>
</div>
<div className="col-lg-6 col-12 wow bounceInUp">
<div className="about-five-content">

  <h2 className="main-title fw-bold">All IN ONE</h2>
  <p>
    A seamless integration between the technology, process, engineering, and products 
    </p>
  <div className="about-five-tab">

    <div className='cocreatebox mt-5'>
      <div className='cocreat-item'>Develop</div>
      <div className='cocreat-item'>Integrate</div>

    </div>

    <div className='cocreatebox mt-5'>
    
      <div className='cocreat-item'>launch</div>
      <div className='cocreat-item'>AI</div>
    </div>


    
  </div>
</div>
</div>
</div>
</div>

</section>

<div className="section-title-five wow bounceInLeft">
<div className="container">
<div className="row">
<div className="col-12">
  <div className="content">
  
    <h2 className="fw-bold mobmt50">Technology partners</h2>
  
  </div>
</div>
</div>

</div>

</div>

<section className="about-area2 section-padding40w pt176w section-bg2 services-eightw pb-bottom">
<div className="container">
{/* <div className='row'>
<div className='stackbox col-lg-12 col-md-6 col-12'>
<div className='stackbox-itme'>
  <h4>Cloud </h4>
  <img
          src={cloudlogos}
          alt="Thumb"
        />
    </div>
<div className='stackbox-itme service-content'>   <h4>on-Prem</h4>
<img
          src={onprem}
          alt="Thumb"
        />
    
    </div>
    <div className='stackbox-itme'>
    <h4>Application </h4>
    <img
          src={applicationlogo}
          alt="Thumb"
        />
  </div>
  </div>
</div> */}

<div className="row">
<div className="col-lg-4 col-md-6 wow bounceInDown">
<div className="single-services">
  <div className="service-icon">
    <i className="lni lni-capsule"></i>
  </div>
  <div className="service-content text-center">
  <h4>Cloud </h4>
  <img
          src={cloudlogos}
          alt="Thumb"
        />
  </div>
</div>
</div>
<div className="col-lg-4 col-md-6 wow bounceInUp">
<div className="single-services">
  <div className="service-icon">
    <i className="lni lni-bootstrap"></i>
  </div>
  <div className="service-content text-center">
  <h4>on-Prem</h4>
<img
          src={onprem}
          alt="Thumb"
        />
  </div>
</div>
</div>
<div className="col-lg-4 col-md-6 wow bounceInDown">
<div className="single-services">
  <div className="service-icon">
    <i className="lni lni-shortcode"></i>
  </div>
  <div className="service-content text-center">
  <h4>Application </h4>
    <img
          src={applicationlogo}
          alt="Thumb"
        />
  </div>
</div>
</div>

</div>
</div>
</section> 

<div className="section-title-five ">
<div className="container">
<div className="row">
<div className="col-12 wow bounceInUp">
  <div className="content">
  
    <h2 className="fw-bold mobmt50">What is in it?</h2>
  
  </div>
</div>
</div>

</div>

</div>

<section className="about-area2 section-padding40w section-bg2 pb-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5 wow bounceInRight">
              <div className="about-caption mb-50">
             
<ul className='newlist'>
<li><i className="pulse green" style={{marginRight:'15px'}}></i> CoCREATE is a platform to Develop, Integrate, and Launch your products seemlesly with AI powered intelligent automation.</li>
  <li><i className="pulse green" style={{marginRight:'15px'}}></i>AI powered context specific code generation & reviews</li>
  <li><i className="pulse green" style={{marginRight:'15px'}}></i>AI powered development and testing & technology recommendation</li>
  <li><i className="pulse green" style={{marginRight:'15px'}}></i>AI powered observability, monitoring, and root cause analysis</li>
</ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 offset-lg-1 col-xs-12">
              <div className="about-img lftimg">
                <img
                  src={uniinfo}
                  alt="Thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

<section className="about-area2 section-padding40w pt176 section-bg2 regularbg">
<div className="container-fluid centertext">
<div className="row align-items-center">
<div className='col-lg-4 col-sm-6 col-md-4 col-xs-12 offset-lg-1 wow bounceInUp'>
<div className='realestateblockw'>
  <h1 className='whitecolor'>AI driven Automation</h1>
</div>
</div>
</div>
</div>
</section>

<section className="about-area2 section-padding40 section-bg2 vectorbg1">
<div className="container-fluid">
<div className="row align-items-center">
<div className='col-lg-10 col-sm-6 col-md-4 col-xs-12 offset-lg-1'>
<div className='realestateblock'>

<h2 className="fw-bold mobmt50">Why CoCREATE ?</h2>

<ul className='mt-5'>
<li> <i className="pulse green" style={{marginRight:'15px'}}></i>A cutting-edge platform designed to revolutionize the way software development projects are initiated, executed, and delivered. </li>
<li><i className="pulse green" style={{marginRight:'15px'}}></i>An integrated ecosystem that empowers developers, streamlines collaboration, and accelerates the development lifecycle. </li>
<li><i className="pulse green" style={{marginRight:'15px'}}></i>Its not just a platform; it's a dynamic solution that caters to the evolving needs of modern development teams.</li>
<li><i className="pulse green" style={{marginRight:'15px'}}></i>A comprehensive toolkit that provides an end-to-end solution for developers, enterprises, and businesses seeking agility, efficiency, and innovation in their software development endeavors.</li>
</ul>
</div>

</div>
</div>
</div>
</section>


{/* <div className="about-area2 section-bg2">
<div className="container">
<div className="row  align-items-center">

<div className='col-lg-6 col-sm-6 col-xs-12 col-12 v-100'>
<div className='ideation'>
  <div>

  <h1>It's Cocreate</h1>

  <h4>It's ideation to launch</h4>
  </div>
</div>



</div>



</div>
</div>

</div> */}

<section className="about-area2 section-padding40 pt176w section-bg2 ideationbg mb-100w">


<div className="container-fluid">
<div className="row align-items-center">
<div className='col-lg-9 col-sm-6 col-md-9 col-12 offset-lg-1 wow bounceInDown'>
<div className='realestateblockw centertext'>
<h1 className='whitecolor ptmobile'>It's CoCREATE<div>It’s IDEATION to LAUNCH</div></h1>
</div>
</div>
</div>
</div>
</section>

<Footer/>
</>
)
}

export default Cocreate