import { useEffect } from 'react';
import WOW from 'wowjs';
import Footer from './Footer'
import Npp from '../../../assets/js/bootsnav'
import img260 from './../../../assets/img/hero-img4.webp';
import img270 from './../../../assets/img/hero-img5.webp';
import img280 from './../../../assets/img/hero-img6.jpg';
import img290 from './../../../assets/img/hero-img26.webp';
import img300 from './../../../assets/img/hero_img_33.jpg';
import xrvr1 from './../../../assets/img/gallery/app_devlopment_flat1.png'
import img440 from './../../../assets/img/new-secion-vr.png';
import AddPage from './../../admin/AddPage';
import AddDevSlider from './AddDevSlider';
import Seo from './seo/Seo';
import { seoData } from './seo/SeoContent';
function ApplicationDevlopment() {
  const divStyle = {
        backgroundImage: `url(${img440})`
    }

    useEffect(() => {
      const wow = new WOW.WOW({live: false});
      wow.init({
        duration: 1000
      });
      
    }, []);

  return (
    <>
     <Seo title={seoData.ApplicationDevlopment.title} description={seoData.ApplicationDevlopment.description}/>
      <Npp />
      <section id="info--1">
      <section className="about-area2 section-padding40w pt176 section-bg2">
            <div className="container">
              <div className="about-items">
                <div className="row align-center1">
                  <div className="col-lg-5 col-md-5 wow bounceInLeft">
                  <div className="about-caption mb-50">
                  <h2 className='mb-2 text-black-40 text-left'>
                        Exceptional Application Development by Colate
                      </h2>
                      <p className="text-black-40 mt-5">
                        Embark on a transformative journey with Colate, where we redefine the landscape of application development.
                      </p>
                      <p className="text-black-40">
                        Our approach blends innovation with expertise, crafting bespoke digital solutions that resonate with your audience and drive exceptional user experiences.
                      </p>
                      <p className="text-black-40">
                        We take pride in crafting exceptional digital experiences that leave a lasting impression. Explore our comprehensive application development services and discover why Colate is your ideal partner for transforming ideas into reality.
                      </p>

                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 offset-lg-1 col-xs-12 wow bounceInRight">
                    <div className="thumb">
                      <img
                        src={xrvr1}
                        alt="Thumb"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </section>
      <section id="about" className="p-5">
          <div className="container text-center">
            <div className="pb-5">
              <h3 className="mb-2 text-black-40 text-left">Why Choose Colate for Your Application Development Needs?</h3>
            </div>
            {/* cards */}
            <div className="row justify-content-center">
              {/* card 1 */}
              <div className="col-lg-4 wow bounceInDown">
                <div className="app card">
                  <img
                    src={img260}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>
                        <i className="fas fa-mobile-alt" />
                      </span>
                      <br />
                      Innovative Mobile Experiences
                    </h5>
                    <p className="card-text" >
                      <p>
                        Envision groundbreaking mobile apps tailored to your brand's identity.
                      </p>
                      <p>Our team combines creativity and technical expertise to design intuitive iOS and Android applications that captivate users and drive engagement.</p>
                    </p>
                  </div>
                </div>
              </div>
              {/* card 2 */}
              <div className="col-lg-4 wow bounceInUp">
                <div className="app card">
                  <img
                    src={img270}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>
                        <i className="fas fa-mobile-alt" />
                      </span>
                      <br />
                      Seamless Modernization Solutions
                    </h5>
                    <p className="card-text" >
                      <p>
                        Elevate your existing applications with our modernization services. We breathe new life into legacy systems, making them agile, secure, and scalable.
                      </p>
                      <p>
                        Experience a smooth transition into the future of digital technology.
                      </p>
                    </p >
                  </div>
                </div>
              </div>
              <div className="col-lg-4  wow bounceInDown">
                <div className="app card">
                  <img
                    src={img280}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>
                        <i className="fas fa-mobile-alt" />
                      </span>
                      <br />
                      Microservices Mastery
                    </h5>
                    <p>
                      Embrace the power of microservices architecture.
                    </p>
                    <p>Our experts implement best practices, enabling seamless communication between components. Scale effortlessly, adapt swiftly, and enhance your system's reliability.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow bounceInUp">
                  <div className="app card">
                    <img
                      src={img300}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                        <span>
                          <i className="fas fa-mobile-alt" />
                        </span>
                        <br />
                        Native Excellence and Cross-Platform Reach
                      </h5>
                      <p>
                        Reach wider audiences with our native and cross-platform solutions.
                      </p>
                      <p>Whether you need high-performance native applications or unified experiences across multiple platforms, our expertise ensures your apps shine everywhere.</p>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4 wow bounceInDown">
                <div className="app card">
                  <img
                    src={img290}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      <span>
                        <i className="fas fa-mobile-alt" />
                      </span>
                      <br />
                      Responsive React Web Magic
                    </h5>
                    <p>
                      Captivate online visitors with visually stunning and responsive web applications powered by React.js.
                    </p>
                    <p> ....</p>
                    <p>We create immersive interfaces that adapt seamlessly to various devices, ensuring smooth navigation and engaging interactions.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
<AddDevSlider/>


      <section className="wantToWork-area default-padding">
          <div className="container">
            <div
              className="wants-wrapper w-padding2 section-bg2"
              style={divStyle}
            >
              <div className="row align-items-center justify-content-center wow bounceInLeft">
                <div className="col-xl-7 col-lg-9 col-md-8">
                  <div className="wantToWork-caption text-center">
                    <h2>Ready to redefine your digital footprint?</h2>
                    <p>
                      Experience the Colateio difference. Let's transform your ideas into unforgettable digital experiences. Connect with us today, and let the journey to excellence begin!
                    </p>
                    <a href="/Contact" className="btn3 wantToWork-btn">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
      </section>
      <Footer />
    </>
  )
}

export default ApplicationDevlopment
