import { faAngleDoubleLeft, faAngleDoubleRight, faArrowLeftLong, faB, faClock, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorType } from '../Author';
import "./AllBlog.css";
import { categoryType } from '../Category';
import fb from './../../../assets/img/fb.jpg';
import tw from './../../../assets/img/tw.jpg';
import fbs from './../../../assets/img/in.jpg';
import pin from './../../../assets/img/pin.jpg';
import blogtop from './../../../assets/img/top-bg-blog.jpg';
import Npp from '../../../assets/js/bootsnav';
import Footer from '../../UI/common/Footer';
function AllBlog() {
    const Metrics = {
        backgroundImage: `url(${blogtop})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

        zIndex: -1,
        minHeight: '300px',

    }
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState<any>([]);
    const [author, setAuthor] = useState<authorType[]>([]);
    const [category, setCategory] = useState<any>([]);
    const [recentBlogs, setRecentBlogs] = useState<any>([]);
    const [searchValue, setSearchValue] = useState('');
    const [singleBlog, setSingleBlog] = useState(false);
    const [singlePageData, setSinglePageData] = useState<any>({})
    const [title, setTitle] = useState('All Blogs')
    const getBlogs = () => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BASE_URL}/allPage?nocache=true`).then((res) => {
            if (res?.status === 200) {
                setTitle('All Blogs')
                setPageData(res.data);
                setSingleBlog(false);
                setLoading(false);
            }
        })
    }
    const loadAuthor = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allauthor?limit=6&nocache=true`).then(res => {
            setAuthor([...res.data]);
        })
    }
    const categoryByCount = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/categorycount?nocache=true`).then((res) => {
            if (res?.status === 200) {
                setCategory(res.data);
            }
        })
    }
    const getRecentBlogs = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/recentPage?nocache=true`).then((res) => {
            if (res?.status === 200) {
                setRecentBlogs(res.data);
            }
        })
    }
    useEffect(() => {
        getBlogs();
        loadAuthor();
        categoryByCount();
        getRecentBlogs();
    }, [])

    const handleReadMore = (id: any) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}/blog`, { id }).then((res) => {
            if (res?.status === 200) {
                setSinglePageData(res.data[0]);
                setSingleBlog(true);
                setLoading(false)
            }
        })
    }
    /* const handleReadMore = (id: any) => {
        setLoading(true)
        loadSingleBlog(id);
    } */
    const loadSingleBlog = (blog:any) => {
        navigate(`/blog/${blog.title}`, {
            state: blog._id
        })
    }

    /* const handleDelete = (id: any) => {
        axios.delete(`${process.env.REACT_APP_BASE_URL}/deletePage`).then((res) => {
            if (res?.status === 200) {
                setRecentBlogs(res.data);
            }
        })
    } */
    const handleSearch = (event: any) => {
        event.preventDefault();
        if (searchValue) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/search`, { searchValue }).then((res) => {
                if (res?.status === 200) {
                    setTitle('Search Result');
                    setPageData(res.data);
                }
            })
        } else {
            getBlogs();
        }
    }
    const loadPageById = (e: any, data: any, type: string) => {
        e.preventDefault();
        const payload = {
            type,
            id: data._id
        }
        
        axios.post(`${process.env.REACT_APP_BASE_URL}/blogbyid`, payload).then((res) => {
            if (res?.status === 200) {
                if(type === 'category') {
                    setTitle(res?.data[0]?.category[0]?.name)
                }else {
                    setTitle(res?.data[0]?.author[0]?.name)
                }
                setPageData(res.data);
            }
        })
    }

    return (
        <>
            <Npp />
            {/* <div className="blogwrapper">
                <img src={require("../../../assets/img/blogImg.jpg")} />
            </div> */}
            <div className="blog-area full-blog right-sidebar full-blog default-padding top_banner_for_post" >
                <div className="container blogHeading">
                <h4 >{title}</h4>
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content blgwrapper pt22222 col-lg-8 col-md-12">
                                <div className="blog-item-box ">
                                    {singleBlog && loading ? (
                                        <div className="spinner-border text-secondary loader" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div>
                                    ) : singleBlog && singlePageData && (
                                        <div className="item">
                                            <div className="thumb">
                                                <img src={singlePageData?.img} alt="Thumb" />
                                                <div className="date">{singlePageData?.createdDate.split('T')[0]}</div>
                                            </div>

                                            <div className="info">
                                                <h3>{singlePageData?.title}</h3>

                                                <div className="footer-meta">
                                                    <ul>
                                                        <li>
                                                            <img src={singlePageData?.author[0].profilePicture} alt="Author" />
                                                            <span>By </span>
                                                            <a href="#">{singlePageData?.author[0].name}</a>
                                                        </li>
                                                        <li>
                                                            <span>In </span>
                                                            <a href="#">{singlePageData?.category[0].name}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: singlePageData?.description }}></div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {!singleBlog && loading ? (
                                        <div className="spinner-border text-secondary loader" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div>
                                    ) : !singleBlog && pageData.map((item: any) => (
                                        <div className="single-item" key={item._id}>
                                            <div className="item">
                                                <div className="thumb blogsBanner">
                                                    <img src={item.img} alt="Thumb" />
                                                    <div className="date">{item.createdDate.split('T')[0]}</div>
                                                </div>
                                                <div className="info">
                                                    <h3>
                                                        <a href="#">{item.title}</a>
                                                    </h3>
                                                    <div className="footer-meta">
                                                        <ul>
                                                            <li>
                                                                <img src={item.author[0].profilePicture} alt="Author" />
                                                                <span>By </span>
                                                                <a href="#">{item.author[0].name}</a>
                                                            </li>
                                                            <li>
                                                                <span>In </span>
                                                                <a href="#">{item.category[0].name}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: item.overView }}></p>
                                                    <a className="btn circle btn-theme border btn-sm" onClick={() => loadSingleBlog(item)}>Read More</a>
                                                    {/* <a className="btn circle btn-theme border btn-sm dltBtn" onClick={() => handleDelete(item._id)}>Delete</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* TODO: Pagination */}
                               {/*  {
                                    pageData.length > 4 && (
                                        <div className="row">
                                            <div className="col-md-12 pagi-area text-center">
                                                <nav aria-label="navigation">
                                                    <ul className="pagination">
                                                        <li className="page-item"><a className="page-link" href="#"><FontAwesomeIcon icon={faAngleDoubleLeft} /> </a></li>
                                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                        <li className="page-item"><a className="page-link" href="#"><FontAwesomeIcon icon={faAngleDoubleRight} /> </a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    )
                                } */}

                            </div>
                            <div className="sidebar col-lg-4 col-md-12">
                                <aside>
                                    <div className="sidebar-item search">
                                        <div className="sidebar-info">
                                            <form>
                                                <input type="text" name="text" className="form-control searchInput" onChange={(event) => setSearchValue(event?.target.value)} />
                                                <button type="submit" onClick={handleSearch}><FontAwesomeIcon icon={faSearch} /></button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="sidebar-item recent-post">
                                        <div className="title">
                                            <h4>Recent Posts</h4>
                                        </div>
                                        <ul>
                                            {recentBlogs.map((item: any) => (
                                                <li key={item._id}>
                                                    <div className="thumb">
                                                        <a href="#">
                                                            <img src={item.author[0].profilePicture} alt="Thumb" />
                                                        </a>
                                                    </div>
                                                    <div className="info">
                                                        <div className="meta-title">
                                                            <span className="post-date"><FontAwesomeIcon icon={faClock} /> {item.createdDate.split('T')[0]}</span>
                                                        </div>
                                                        <a href="#" onClick={(e: any) => { e.preventDefault(); handleReadMore(item._id) }}>{item.overView.substr(0, 40)}...</a>
                                                    </div>
                                                </li>
                                            ))}


                                        </ul>
                                    </div>
                                    <div className="sidebar-item category">
                                        <div className="title">
                                            <h4>category list</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                {category.map((item: any) => (
                                                    <li key={item._id}>
                                                        <a onClick={(e) => loadPageById(e, item, 'category')} href="#">{item.data[0].categoryName} <span>{item.count}</span></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sidebar-item gallery">
                                        <div className="title">
                                            <h4>Authors</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                {author.filter(a => a?.blog?.length).map(au => (
                                                    <li key={au._id}>
                                                        <a href="#" onClick={(e) => loadPageById(e, au, 'author')}>
                                                            <img src={au.profilePicture} alt="thumb" />
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="sidebar-item archives">
                                <div className="title">
                                    <h4>Archives</h4>
                                </div>
                                <div className="sidebar-info">
                                    <ul>
                                        <li><a href="#">Aug 2021</a></li>
                                        <li><a href="#">Sept 2021</a></li>
                                        <li><a href="#">Nov 2021</a></li>
                                        <li><a href="#">Dec 2021</a></li>
                                    </ul>
                                </div>
                            </div> */}
                                    <div className="sidebar-item social-sidebar">
                                        <div className="title">
                                            <h4>follow us</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                <li className="facebook">
                                                    <a href="#">

                                                        <img
                                                            src={fb}
                                                            alt="Thumb"
                                                        />
                                                        {/* <FontAwesomeIcon icon="fa-brands fa-facebook-f" /> */}
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="#">
                                                        <img
                                                            src={tw}
                                                            alt="Thumb"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="#">
                                                        <img
                                                            src={pin}
                                                            alt="Thumb"
                                                        />
                                                    </a>
                                                </li>

                                                <li className="linkedin">
                                                    <a href="#">
                                                        <img
                                                            src={fbs}
                                                            alt="Thumb"
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* TODO: Tags */}
                                    {/* <div className="sidebar-item tags">
                                        <div className="title">
                                            <h4>tags</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                <li><a href="#">Fashion</a>
                                                </li>
                                                <li><a href="#">Education</a>
                                                </li>
                                                <li><a href="#">nation</a>
                                                </li>
                                                <li><a href="#">study</a>
                                                </li>
                                                <li><a href="#">health</a>
                                                </li>
                                                <li><a href="#">food</a>
                                                </li>
                                                <li><a href="#">travel</a>
                                                </li>
                                                <li><a href="#">science</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default AllBlog