import { useEffect } from 'react';
import WOW from 'wowjs';
import visbg from './../../../assets/img/devops-release-management-left.jpg';
import misBgimg from './../../../assets/img/mission-bg-plan.jpeg';
import Npp from '../../../assets/js/bootsnav'
import Footer from './Footer';
import { Link } from "react-router-dom"
import uniinfo from './../../../assets/img/stratagy_left.png';
import img440 from './../../../assets/img/new-secion-vr.png';
import './trusted.css'

function Trustedpartner() {
  useEffect(() => {
    const wow = new WOW.WOW({live: false});
    wow.init({
      duration: 1000
    });
    
  }, []);
  const divStyle = {
      backgroundImage: `url(${img440})`
  }

  const misBg = {
    backgroundImage: `url(${misBgimg})`
  }
  useEffect(() => {
    document.body.classList.remove('dark-layout');
  }, []);

  return (
    <>

      <section className="about-area2 section-padding40w 2pt100 section-bg2 trusted">
        <div className="container-fluid centertext">
          <div className="row align-items-center">
     <div className='col-lg-4 col-sm-6 col-md-4 col-xs-12 offset-lg-1 vdiv'>
          <div className='realestateblock2'>
            <h1 className='whitecolor wow bounceInUp'>Welcome to<span className='block'></span>
             Colate</h1>

<p className='mt-5'>Welcome to Colate, your trusted partner in harnessing the power of Artificial Intelligence (AI) and Intelligent Automation (IA) for transformative solutions in Fintech, Digital Transformation, and Telecommunications. Our innovative technologies are designed to streamline operations, enhance customer experiences, and drive growth. Explore our offerings and discover how we can help your business thrive in the digital age.</p>
          </div>
          </div>
          </div>
        </div>
      </section>


  
    </>
  )
}

export default Trustedpartner