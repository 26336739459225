import React from 'react';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight,faHandPointLeft } from '@fortawesome/free-solid-svg-icons';
function Footer() {
  return (
    <footer className="bg-dark text-light">
    <div className="container">
        <div className="f-items default-padding">
            <div className="row">
                <div className="col-lg-5 col-md-6 item">
                    <div className="f-item about">
                        <h4 className="widget-title">Join Us On Our Journey</h4>
                        <p>
                        We invite you to join us on this exciting journey. Together, let's create a future where human minds create technologies easing out people’s lives. Explore our website to discover how we can collaborate and innovate to solve your unique challenges
                        </p>

                        <div className="social">
                            <ul>
                                <li className="facebook">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li className="twitter">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="youtube">
                                    <a href="#"><i className="fab fa-youtube"></i></a>
                                </li>
                                <li className="instagram">
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 col-md-6 item">
                    <div className="f-item link">
                        <h4 className="widget-title">Services</h4>
                        <ul>
                            <li>      <Link to="/EmergingTechnologies">Emerging Technologies</Link></li>
                            <li>      <Link to="/Fintech">Fintech</Link></li>
                            <li>          <Link to="/Telecommunication">Telecommunication</Link></li>
                            <li>
          <Link to="/Healthcare">Healthcare</Link>
          </li>
                            {/* <li><a href="automation.html">Automation</a></li> */}
                        </ul>
                    </div>
                </div>

                <div className="col-lg-2 col-md-6 item">
                    <div className="f-item link">
                        <h4 className="widget-title">Company</h4>
                        <ul>
                 
                        <li>
      <Link to="/Uniqueness">Uniqueness</Link>
      </li>
      <li>
      <Link to="/Vision">Vision</Link>
      </li>
      <li>
      <Link to="/Mission">Mission</Link>
      </li>
      <li>
      <Link to="/Strategy">Strategy</Link>
      </li>

                        </ul>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 item">
                    <div className="f-item contact">
                        <h4 className="widget-title">Contact Us</h4>
                        <div className="address">
                            <ul>
                                <li>
                                    <p>Address</p> 
                                    <strong>
                                        London, UK<br />
                                        New Jersey, USA<br />
                                        Bengaluru, IND
                                    </strong>
                                </li>
                                <li>
                                    <p>Email</p> 
                                    <strong>admin@colate.io</strong>
                                </li>
                                <li>
                                    <p>Contact</p> 
                                    <strong>+44-77-7424-9876</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  {/* footer */}
    <div className="footer-bottom">
        <div className="container">
            <div className="row align-center">
                <div className="col-lg-4">
                    <p>&copy; Copyright 2023. All Rights Reserved</p>
                </div>
                <div className="col-lg-4 text-center logo col-xs-12"> 
                   {/* <h2 className="logo logo-display">COLATE.IO</h2> */}
                   {/* <img src={require('./../../../assets/img/logo-no-background-0.png')} className="logo logo-scrolled" alt="Logo" /> */}
                   <h1 className="colate-logo logo logo-display white-color">colate</h1>
          
                </div>
                <div className="col-lg-4 text-right newsletter">
                    <form action="#">
                        <div className="input-group stylish-input-group">
                            <input type="email" placeholder="Subscribe for latest updates, Enter your email." className="form-control" name="email" />
                            <span className="input-group-addon">
                                <button type="submit">
                                <FontAwesomeIcon icon={faHandPointLeft}></FontAwesomeIcon>
                                </button>  
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</footer>
  )
}

export default Footer